import { BlockInstanceProps } from '../Block';
import List3Groups from '../../components/List3Groups';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'type',
    definition: controlOptionDefinitions.list3GroupTypes,
  },
])
class List3GroupsBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor, type } = this.controlOptions;

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getFixedAmountOfType(ContentTypeEnum.MEDIAITEM, 3);
    });

    return (
      <List3Groups
        headerText={block?.header?.main}
        mediaItems={mediaItems}
        link={block?.link}
        type={type}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default List3GroupsBlock;
