import { Component } from 'react';
import { Exception } from '@microsoft/applicationinsights-web';
import FilePlayer from 'react-player/lib/players/FilePlayer';
import screenfull, { Screenfull } from 'screenfull';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faCompress,
  faExpand,
} from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/react';
import { isSafari } from 'react-device-detect';

import * as InternalPropTypes from '../../../constants/internal-types';
import { colorEnum } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';

const useStyles = css({
  position: 'relative',
  float: 'right',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  '&.expanded': {
    zIndex: 1001,
  },
  '& .progress-bar-div': {
    position: 'absolute',
    top: '3px',
    right: 0,
    paddingLeft: '20px',
    marginRight: '10px',
    height: '5px',
    '& .progress': {
      height: '2px',
      marginTop: '12px',
      backgroundColor: 'rgba(217, 217, 217, 0.3)', // color taken from old implementation
    },
    '& .progress-bar': {
      backgroundColor: 'white',
      height: '2px',
      transition: 'width 1s linear',
    },
  },
  '& .react-player': {
    transition: 'width 200ms ease-out',
    '& video': {
      objectFit: 'cover',
    },
  },
  '& .btn': {
    color: colorEnum.white,
    backgroundColor: 'transparent',
    outline: 'none',
    '&:focus': {
      boxShadow: 'none',
    },
  },
  '& .resizeIcon': {
    position: 'absolute',
    top: '30px',
    right: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    border: `solid 3px ${colorEnum.white}`,
    padding: '4px',
    '& .notExpanded, .right, .left': {
      margin: '0 2px',
      transition: 'transform 300ms linear',
    },
    '& .right': {
      transform: 'rotate(-180deg)',
    },
    '& .left': {
      transform: 'rotate(180deg)',
    },
  },
  '& .mobile': {
    position: 'absolute',
    top: '30px',
    right: '10px',
    fontSize: '30px',
    padding: '0',
    border: '0',
    '> path': {
      color: colorEnum.white,
    },
  },
  '& video::-internal-media-controls-overlay-cast-button': {
    display: 'none',
  },
});

type ExpandableVideoProps = {
  video?: InternalPropTypes.Video;
  expandedWidth?: number;
};

interface IExpandableVideoState {
  width: string;
  height: string;
  expanded: boolean;
  fullscreen: boolean;
  playing: boolean;
  played: number;
  loop: boolean;
  duration: number;
}

class ExpandableVideo extends Component<
  ExpandableVideoProps,
  IExpandableVideoState
> {
  player: Element;

  static defaultProps = {
    video: {
      url: null,
      contentType: null,
    },
    expandedWidth: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null,
      expanded: null,
      fullscreen: false,
      playing: true,
      played: 0,
      loop: true,
      duration: 30,
    };
    this.resizeWindow = this.resizeWindow.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
  }

  componentDidMount() {
    this.handleWindowSizeChange();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const { isFullscreen } = screenfull as Screenfull;
    if (!isFullscreen) {
      const windowWidth = window.innerWidth;
      let width =
        windowWidth < Breakpoints.Desktop.narrowestWidthInPx
          ? '268px'
          : '343px';
      let height =
        width === '268px' &&
        windowWidth >= Breakpoints.Tablet.narrowestWidthInPx
          ? '372px'
          : '431px';
      width =
        windowWidth < Breakpoints.Tablet.narrowestWidthInPx ? '100%' : width;
      height =
        windowWidth < Breakpoints.Tablet.narrowestWidthInPx ? '100%' : height;
      this.setState({
        width,
        height,
        expanded: false,
        fullscreen: isFullscreen,
      });
    }
  };

  handleProgress = (state) => {
    this.setState(state);
  };

  handleDuration = (state: number) => {
    this.setState({ duration: state });
  };

  handleFullscreen = () => {
    const { fullscreen } = this.state;
    if (screenfull.isEnabled) {
      screenfull.toggle(this.player).then(() =>
        !fullscreen
          ? this.setState({
              fullscreen: true,
            })
          : this.setState({
              fullscreen: false,
            }),
      );
    }
    if (fullscreen) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore .lock() exists but definition has been removed from typescript in DOM update 2023/06/21 https://github.com/microsoft/TypeScript/pull/54725/files#diff-dc0eab937d15e62545da3ed7b4f40ad6b24f15dd88fbc6ceda2bfb4ed8356eb0L20901
      // eslint-disable-next-line no-restricted-globals
      screen.orientation.lock('landscape').catch((e: Exception) => {});
      this.handleWindowSizeChange();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore .lock() exists but definition has been removed from typescript in DOM update 2023/06/21 https://github.com/microsoft/TypeScript/pull/54725/files#diff-dc0eab937d15e62545da3ed7b4f40ad6b24f15dd88fbc6ceda2bfb4ed8356eb0L20901
      // eslint-disable-next-line no-restricted-globals
      screen.orientation.lock('landscape').catch((e: Exception) => {});
    }
  };

  ref = (player) => {
    this.player = player;
  };

  resizeWindow() {
    const { expanded } = this.state;
    const { expandedWidth } = this.props;
    if (!expanded) {
      this.setState({
        width: `${expandedWidth ? `${expandedWidth}px` : '100%'}`,
        expanded: true,
      });
    } else {
      this.setState({ expanded: false });
      this.handleWindowSizeChange();
    }
  }

  render() {
    const {
      played,
      height,
      width,
      expanded,
      playing,
      fullscreen,
      loop,
      duration,
    } = this.state;
    const { video } = this.props;

    const urls = video.videos.map((item) => item.url);

    const fontawesomeArrowDefinition = (icon, spin) => (
      <FontAwesomeIcon
        className={`${expanded ? spin.direction : 'notExpanded'}`}
        icon={icon}
        size="xs"
      />
    );
    const fontawesomeMobileDefinition = (icon) => (
      <FontAwesomeIcon className="mobileIcon" icon={icon} size="sm" />
    );
    return (
      <div
        className={this.state.expanded ? 'expanded' : ''}
        css={useStyles}
        ref={this.ref}
      >
        <div className="progress-bar-div" css={{ width }}>
          <ProgressBar now={(100 * played + 1 + 100 / duration) % 100} />
        </div>
        <FilePlayer
          className="react-player"
          playing={playing}
          muted
          preload="true"
          playsinline={!fullscreen}
          loop={loop}
          width={width}
          height={height}
          url={urls}
          onDuration={this.handleDuration}
          onProgress={this.handleProgress}
        />

        {fullscreen && (
          <button
            type="button"
            className="mobile btn"
            aria-label="minimize"
            onClick={() => this.handleFullscreen()}
          >
            {fontawesomeMobileDefinition(faCompress)}
          </button>
        )}

        {!fullscreen && !isSafari && (
          <button
            type="button"
            className="mobile btn d-block d-md-none"
            aria-label="fullscreen"
            onClick={() => this.handleFullscreen()}
          >
            {fontawesomeMobileDefinition(faExpand)}
          </button>
        )}

        {!fullscreen && (
          <button
            type="button"
            className="resizeIcon btn d-none d-md-block"
            aria-label="fullscreen"
            onClick={() => {
              this.resizeWindow();
            }}
          >
            {fontawesomeArrowDefinition(faArrowLeft, { direction: 'left' })}
            {fontawesomeArrowDefinition(faArrowRight, { direction: 'right' })}
          </button>
        )}
      </div>
    );
  }
}

export default ExpandableVideo;
