import { Component } from 'react';

import TitleTextGroup from './TitleTextGroup';

import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';

type FactBoxProps = {
  type?: TitleTypes;
  titleText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
};

class FactBox extends Component<FactBoxProps> {
  static types = TitleTypes;

  static defaultProps = {
    type: TitleTypes.CONTACT,
    titleText: null,
    bodyText: null,
  };

  render() {
    const { type, titleText, bodyText } = this.props;
    return (
      <div>
        <TitleTextGroup bodyText={bodyText} titleText={titleText} type={type} />
      </div>
    );
  }
}

export default FactBox;
