import { Component } from 'react';
import Lottie from 'react-lottie-light';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';

const styles = {
  '& .animation': {
    [Breakpoints.Mobile.mq]: {
      maxWidth: '300px',
      display: 'block',
      margin: 'auto',
    },
    [Breakpoints.Tablet.mq]: {
      maxWidth: '360px',
    },
    [Breakpoints.DesktopUp.mq]: {
      maxWidth: '400px',
    },
  },
};

type SVGRendererProps = {
  animation: InternalPropTypes.ImageFile;
};

class SVGRenderer extends Component<SVGRendererProps> {
  render() {
    const { animation } = this.props;
    return animation ? (
      <div css={styles}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            path: animation.url,
            rendererSettings: {
              progressiveLoad: true,
              className: 'animation',
            },
          }}
          isClickToPauseDisabled
        />
      </div>
    ) : null;
  }
}

export default SVGRenderer;
