import React, {
  forwardRef,
  createRef,
  Children,
  cloneElement,
  Component,
  PropsWithChildren,
} from 'react';
import { css } from '@emotion/react';
import 'core-js/es/array/includes';

const dotStyles = css({
  transitionProperty: 'transform,opacity',
  transitionTimingFunction: 'ease',
  transitionDuration: '250ms',
  transitionDelay: '100ms',
  willChange: 'transform,opacity',
  position: 'absolute',
  userSelect: 'none',
  opacity: 1,
});

const Dot = forwardRef((props, ref: React.RefObject<HTMLLIElement>) => (
  <li ref={ref} className="nav-list-indicator-dot" css={dotStyles}>
    &#9679;
  </li>
));

type NavListIndicatorChildProps = {
  ref: React.RefObject<HTMLLIElement>;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

type NavListIndicatorProps = PropsWithChildren<{
  dotLeftOffsetInPx?: number;
}>;

class NavListIndicator extends Component<NavListIndicatorProps> {
  static defaultProps = {
    dotLeftOffsetInPx: 0,
    children: null,
  };

  private childRefs: React.RefObject<HTMLLIElement>[];

  private dotRef: React.RefObject<HTMLLIElement>;

  private parkingOffset: number;

  private dotPositions: number[];

  // aka "the blue dot"
  constructor(props) {
    super(props);
    this.childRefs = [];
    this.dotRef = createRef();
    this.parkingOffset = null;
    this.dotPositions = [];
    this.renderChildren = this.renderChildren.bind(this);
    this._onMouseEnter = this._onMouseEnter.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
  }

  componentDidMount() {
    this._updatePositions();
    this._moveDotTo(this.parkingOffset, false);
  }

  componentDidUpdate() {
    this._updatePositions();
  }

  _moveDotTo(offSet, animate = true) {
    if (offSet !== null) {
      // figure out if dot is currently visible. if the dot is hidden, don't animate the movement of the dot, just fade it in
      const dotIsVisible =
        Number(getComputedStyle(this.dotRef.current).opacity) > 0;
      // eslint-disable-next-line no-nested-ternary
      this.dotRef.current.style.transitionProperty = animate
        ? dotIsVisible
          ? 'transform,opacity'
          : 'opacity'
        : 'none';
      this.dotRef.current.style.transform = `translateX(${offSet}px)`;
      this.dotRef.current.style.opacity = '1';
    } else {
      this.dotRef.current.style.opacity = '0';
    }
  }

  _onMouseEnter(idx) {
    this._moveDotTo(this.dotPositions[idx]);
  }

  _onMouseLeave() {
    this._moveDotTo(this.parkingOffset);
  }

  // make a static map of the different dot positions and note
  // the dot parking position, if a link is currently active
  _updatePositions() {
    let leftMostOffsetLeft = 0;
    this.dotPositions = [];
    this.parkingOffset = null;
    this.childRefs.forEach((ref) => {
      const curOffsetLeft = ref.current.getBoundingClientRect().left;
      leftMostOffsetLeft = leftMostOffsetLeft || curOffsetLeft;
      const curDotPosition =
        curOffsetLeft - leftMostOffsetLeft - this.props.dotLeftOffsetInPx;
      this.dotPositions.push(curDotPosition);
      const curIsActive = ref.current.className.split(/\s+/).includes('active');
      if (curIsActive) {
        this.parkingOffset = curDotPosition;
      }
    }, this);
  }

  // attach a ref and mouse event handlers to each of the li's that are the list items and inject the Dot li
  renderChildren() {
    let idx = -1;
    // eslint-disable-next-line react/prop-types
    const result = Children.map(this.props.children, (child) => {
      idx += 1;
      const curIdx = idx;
      this.childRefs[idx] = this.childRefs[idx] || createRef();
      return cloneElement(
        child as React.ReactElement<NavListIndicatorChildProps>,
        {
          ref: this.childRefs[idx],
          onMouseEnter: () => this._onMouseEnter(curIdx),
          onMouseLeave: this._onMouseLeave,
        },
      );
    }).concat([<Dot ref={this.dotRef} key="nav-list-indicator-dot" />]);
    this.childRefs = this.childRefs.slice(0, idx + 1);
    return result;
  }

  render() {
    return <>{this.renderChildren()}</>;
  }
}

export default NavListIndicator;
