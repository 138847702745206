import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import ImageCarouselMolecule from './common/molecules/ImageCarousel';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';

const styles = {
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '144px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '104px',
  },
};
type ImageCarouselProps = {
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  images?: InternalPropTypes.Image[];
  backgroundColor?: string;
};

class ImageCarousel extends Component<ImageCarouselProps> {
  static defaultProps = {
    header: null,
    lightHeader: null,
    images: [],
    backgroundColor: null,
  };

  render() {
    const { header, lightHeader, images, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div className="image-carousel-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <ImageCarouselMolecule
              headerText={header}
              lightHeaderText={lightHeader}
              images={images}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ImageCarousel;
