import { ContentTypeEnum } from '../../constants/cms-constants';
import CaseGrid from '../../components/CaseGrid';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  { key: 'showNumbering', definition: controlOptionDefinitions.boolean },
])
class CaseGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { blockContent } = this.props;
    const { backgroundColor, showNumbering } = this.controlOptions;

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <CaseGrid
        items={mediaItems}
        backgroundColor={backgroundColor}
        showNumbering={showNumbering}
      />
    );
  }
}

export default CaseGridBlock;
