import { Component, Fragment } from 'react';
import { css } from '@emotion/react';
import Image from '../atoms/Image';
import Caption from '../atoms/Caption';
import Header from '../atoms/Header';
import BodyText from '../atoms/BodyText';
import NwLink from '../NwLink';

import {
  filterEmail,
  filterPhone,
} from '../../../utils/filterContactInformation';
import {
  generateEmailProps,
  generatePhoneProps,
} from '../../../utils/generateContactInfoProps';

import * as InternalPropTypes from '../../../constants/internal-types';
import { colorEnum } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  width: '100%',
  maxWidth: '321px',
  marginBottom: '48px',
  ':hover': {
    '& a': {
      textDecoration: 'none',
    },
  },
  '.name-contact, .title-contact, .body-text': {
    color: colorEnum.lightGray,
  },
  '& .image': {
    width: '132px',
    height: '132px',
    marginBottom: '24px',
    [Breakpoints.Tablet.mq]: {
      width: '100px',
      height: '100px',
    },
  },
  '& .caption': {
    marginTop: '8px',
    marginBottom: '26px',
    textTransform: 'uppercase',
  },
  '& .light14': {
    paddingBottom: '24px',
    lineHeight: '18px',
  },
  '& .enquiry-contact': {
    marginTop: '8px',
  },
});

type ProfileContactProps = {
  link?: InternalPropTypes.Link;
  // eslint-disable-next-line no-restricted-globals
  name?: string;
  jobTitle?: string;
  description?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  email?: string;
  phone?: string;
  stopAnimation?: boolean;
  clickableContactInfo?: boolean;
};

class ProfileContact extends Component<ProfileContactProps> {
  static defaultProps = {
    link: null,
    name: '',
    jobTitle: '',
    description: null,
    image: null,
    email: '',
    phone: '',
    stopAnimation: true,
    clickableContactInfo: false,
  };

  render() {
    const {
      name,
      description,
      jobTitle,
      image,
      email,
      phone,
      link,
      stopAnimation,
      clickableContactInfo,
    } = this.props;

    const phoneProps = clickableContactInfo
      ? generatePhoneProps(filterPhone(phone))
      : {};
    const emailProps = clickableContactInfo
      ? generateEmailProps(filterEmail(email))
      : {};

    const WrapTag = link ? NwLink : Fragment;
    // Have to return empty object for Fragment
    const wrapProps = link
      ? {
          to: link.linkTo,
          'aria-label': `${jobTitle} ${name}`,
          openInNewWindowOrTab: link.openInNewWindowOrTab,
        }
      : {};

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '132', h: '132' },
      [ScreenSize.Tablet]: { w: '100', h: '100' },
      [ScreenSize.Desktop]: { w: '132', h: '132' },
    };

    return (
      <div className="profile-contact" css={styles}>
        <WrapTag {...wrapProps}>
          {image && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <Image image={image} sizes={sizes} />
              </div>
            </div>
          )}

          {name && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <Header type={Header.types.NAMECONTACT}>{name}</Header>
              </div>
            </div>
          )}

          {jobTitle && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <Caption type={Caption.types.TITLECONTACT}>{jobTitle}</Caption>
              </div>
            </div>
          )}

          {description && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <BodyText type={BodyText.types.LIGHT14}>{description}</BodyText>
              </div>
            </div>
          )}

          {email && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <div className="email">
                  <BodyText
                    type={BodyText.types.ENQUIRYCONTACT}
                    {...emailProps}
                  >
                    {email}
                  </BodyText>
                </div>
              </div>
            </div>
          )}

          {phone && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <div className="phone">
                  <BodyText
                    type={BodyText.types.ENQUIRYCONTACT}
                    {...phoneProps}
                  >
                    {phone}
                  </BodyText>
                </div>
              </div>
            </div>
          )}
        </WrapTag>
      </div>
    );
  }
}

export default ProfileContact;
