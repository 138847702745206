import { BlockInstanceProps } from '../Block';
import Quote from '../../components/Quote';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'type',
    definition: controlOptionDefinitions.quoteTypes,
  },
])
class QuoteBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const { backgroundColor, type } = this.controlOptions;

    return (
      <Quote
        quote={block?.header?.main}
        sender={block?.header?.light}
        type={type}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default QuoteBlock;
