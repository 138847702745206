import { compose } from 'recompose';
import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import FileRequestComponent from '../../components/FileRequestComponent';
import { LANGUAGES } from '../../constants/language';
import { getStringFromRichText } from '../../utils/content/RichTextConverter';
import withAppConfig, {
  WithAppConfigProps,
} from '../../utils/hocs/withAppConfig';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

type BlockInstanceInnerProps = WithAppConfigProps & BlockInstanceProps;

function downloadLinkBuilder(link, id, locale, azureStorageAccount) {
  const novatarisDownloadBaseUrl = `https://novataris.com/${locale}/downloads/`;
  const downloadIdentifier = `${id}/${link.replace(
    `https://${azureStorageAccount}.blob.core.windows.net`,
    '',
  )}`;
  return novatarisDownloadBaseUrl + downloadIdentifier;
}

function emailLinks(cmsLinks, blockId, locale, azureStorageAccount) {
  return cmsLinks.map((link) => ({
    text: getStringFromRichText(link.text),
    url: downloadLinkBuilder(link.url, blockId, locale, azureStorageAccount),
  }));
}

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class FileRequestFormBlock extends ComponentWithControlOptions<BlockInstanceInnerProps> {
  render() {
    const { block, blockContent, connectors, appConfig } = this.props;
    const { backgroundColor } = this.controlOptions;
    const { self } = connectors;

    let snippets;
    let cmsLinks;
    let mailContent;
    blockContent.doQuery((content) => {
      snippets = content.getAllOfType(ContentTypeEnum.SNIPPET);
      cmsLinks = content.getAllOfType(ContentTypeEnum.LINK);
      mailContent = content.getFirstOfType(ContentTypeEnum.MAILCONTENT);
    });

    const sendingMessage = snippets.find((s) =>
      s.name.toLowerCase().includes('sending submit'),
    );
    const emailMessage = snippets.find((s) =>
      s.name.toLowerCase().includes('successful submit email'),
    );
    const resubmitMessage = snippets.find((s) =>
      s.name.toLowerCase().includes('successful resubmit'),
    );
    const failedSubmitMessage = snippets.find((s) =>
      s.name.toLowerCase().includes('failed submit'),
    );
    const submitButtonText = snippets.find((s) =>
      s.name.toLowerCase().includes('submit button text'),
    );
    const namePlaceholder = snippets.find((s) =>
      s.name.toLowerCase().includes('name placeholder'),
    );
    const emailPlaceholder = snippets.find((s) =>
      s.name.toLowerCase().includes('email placeholder'),
    );

    const locale = LANGUAGES.find((l) => l.name === block.language)?.iso;

    return (
      <FileRequestComponent
        backgroundColor={backgroundColor}
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        emailMessage={getStringFromRichText(emailMessage.text)}
        resubmitMessage={resubmitMessage.text}
        sendingMessage={sendingMessage.text}
        failedSubmitMessage={failedSubmitMessage.text}
        submitButtonText={submitButtonText.text}
        namePlaceholder={getStringFromRichText(namePlaceholder.text)}
        emailPlaceholder={getStringFromRichText(emailPlaceholder.text)}
        links={emailLinks(
          cmsLinks,
          block.contentfulEntryId,
          locale,
          appConfig.settings.azureStorageAccount,
        )}
        mailContent={mailContent}
        submitFileRequest={self.submitFileRequest}
      />
    );
  }
}

export default compose<BlockInstanceInnerProps, BlockInstanceProps>(
  withAppConfig,
)(FileRequestFormBlock);
