import { Component, Fragment } from 'react';
import { css } from '@emotion/react';

import Image from '../atoms/Image';
import Caption from '../atoms/Caption';
import Header from '../atoms/Header';
import BodyText from '../atoms/BodyText';
import NwLink from '../NwLink';

import {
  filterEmail,
  filterPhone,
} from '../../../utils/filterContactInformation';
import {
  generateEmailProps,
  generatePhoneProps,
} from '../../../utils/generateContactInfoProps';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  [Breakpoints.Mobile.mq]: {
    width: '100%',
    maxWidth: '144px',
    height: 'auto',
  },
  [Breakpoints.Tablet.mq]: {
    width: '100%',
    maxWidth: '180px',
    height: 'auto',
  },
  [Breakpoints.DesktopUp.mq]: {
    width: '100%',
    maxWidth: '246px',
  },
  ':hover': {
    '& a': {
      textDecoration: 'none',
    },
  },
  '& .image, .image-container, .image-container-with-link': {
    objectFit: 'cover',
    [Breakpoints.Mobile.mq]: {
      width: '144px',
      height: '184px',
    },
    [Breakpoints.Tablet.mq]: {
      width: '100%',
      maxWidth: '180px',
      height: '222px',
    },
    [Breakpoints.DesktopUp.mq]: {
      width: '100%',
      maxWidth: '246px',
      height: 'auto',
    },
  },
  '& .image-container-with-link .image': {
    transform: 'scale(1.0)',
    transition: 'all 200ms linear',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 200ms linear',
    },
  },
  '& .image-container, .image-container-with-link': {
    overflow: 'hidden',
    marginBottom: '4px',
  },
  '& .title-base': {
    marginBottom: '4px',
  },
  '& .description': {
    marginBottom: '4px',
  },
});

type ProfileBaseProps = {
  link?: InternalPropTypes.Link;
  // eslint-disable-next-line no-restricted-globals
  name?: string;
  jobTitle?: string;
  description?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  email?: string;
  phone?: string;
  stopAnimation?: boolean;
  clickableContactInfo?: boolean;
};

class ProfileBase extends Component<ProfileBaseProps> {
  static defaultProps = {
    link: null,
    name: '',
    jobTitle: '',
    description: null,
    image: null,
    email: '',
    phone: '',
    stopAnimation: true,
    clickableContactInfo: false,
  };

  render() {
    const {
      name,
      description,
      jobTitle,
      image,
      email,
      phone,
      link,
      stopAnimation,
      clickableContactInfo,
    } = this.props;

    const phoneProps = clickableContactInfo
      ? generatePhoneProps(filterPhone(phone))
      : {};
    const emailProps = clickableContactInfo
      ? generateEmailProps(filterEmail(email))
      : {};

    const WrapTag = link ? NwLink : Fragment;
    // Have to return empty object for Fragment
    const wrapProps = link
      ? {
          to: link.linkTo,
          'aria-label': `${jobTitle} ${name}`,
          openInNewWindowOrTab: link.openInNewWindowOrTab,
        }
      : {};

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '144', h: '184' },
      [ScreenSize.Tablet]: { 'max-w': '180', h: '222' },
      [ScreenSize.Desktop]: { w: '246' },
    };

    return (
      <div className="profile-base" css={styles}>
        <WrapTag {...wrapProps}>
          <div className="text-animation-container">
            <div className={`text-animation${stopAnimation ? '-stopped' : ''}`}>
              <Header type={Header.types.NAMEBASE}>{name}</Header>
            </div>
          </div>

          <div className={`image-container${link ? '-with-link' : ''}`}>
            <Image image={image} sizes={sizes} />
          </div>

          <div className="text-animation-container">
            <div className={`text-animation${stopAnimation ? '-stopped' : ''}`}>
              <Caption type={Caption.types.TITLEBASE}>{jobTitle}</Caption>
            </div>
          </div>

          {description && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <div className="description">
                  <BodyText type={BodyText.types.LIGHT14}>
                    {description}
                  </BodyText>
                </div>
              </div>
            </div>
          )}
          {email && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <div className="email">
                  <BodyText type={BodyText.types.ENQUIRYBASE} {...emailProps}>
                    {email}
                  </BodyText>
                </div>
              </div>
            </div>
          )}

          {phone && (
            <div className="text-animation-container">
              <div
                className={`text-animation${stopAnimation ? '-stopped' : ''}`}
              >
                <div className="phone">
                  <BodyText type={BodyText.types.ENQUIRYBASE} {...phoneProps}>
                    {phone}
                  </BodyText>
                </div>
              </div>
            </div>
          )}
        </WrapTag>
      </div>
    );
  }
}

export default ProfileBase;
