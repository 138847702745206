import { Component } from 'react';
import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import KeyFigureGridComponent from '../../components/KeyFigureGridComponent';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'fontColor',
    definition: controlOptionDefinitions.fontColor,
  },
  {
    key: 'gridType',
    definition: controlOptionDefinitions.keyFigureGridTypes,
  },
])
class KeyFigureGrid extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { blockContent } = this.props;
    const { backgroundColor, fontColor, gridType } = this.controlOptions;

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <KeyFigureGridComponent
        mediaItems={mediaItems}
        gridType={gridType}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
      />
    );
  }
}
export default KeyFigureGrid;
