import React from 'react';
import TextBox from '../../components/TextBox';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import ContentSpacer from '../../components/common/atoms/ContentSpacer';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  { key: 'openInNewTab', definition: controlOptionDefinitions.boolean },
])
class BodyTextBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const { openInNewTab } = this.controlOptions;

    return (
      <ContentOuterBound>
        <ContentSpacer>
          <TextBox input={block?.bodyText} openInNewTab={openInNewTab} />
        </ContentSpacer>
      </ContentOuterBound>
    );
  }
}

export default BodyTextBlock;
