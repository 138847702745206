import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import Grid2CellBroken from './common/molecules/Grid2CellBroken';
import Image from './common/atoms/Image';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import ScreenSize from '../constants/screenSize';
import { SizesType } from '../types/ts/imgixQuery';

const styles = {
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '65px',
    paddingBottom: '65px',
  },
  [Breakpoints.TabletDown.mq]: {
    paddingTop: '57px',
    paddingBottom: '57px',
  },
};

type ImageGrid2CellsProps = {
  images?: InternalPropTypes.Image[];
  backgroundColor?: string;
};

class ImageGrid2Cells extends Component<ImageGrid2CellsProps> {
  static defaultProps = {
    images: [],
    backgroundColor: null,
  };

  render() {
    const { images, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    const leftImageSizes: SizesType = {
      [ScreenSize.Mobile]: { w: '223', h: '260' },
      [ScreenSize.Tablet]: { w: '271', h: '316' },
      [ScreenSize.Desktop]: { w: '300', h: '350' },
    };

    const rightImageSizes: SizesType = {
      [ScreenSize.Mobile]: { w: '372', h: '520' },
      [ScreenSize.Tablet]: { w: '452', h: '632' },
      [ScreenSize.Desktop]: { w: '500', h: '700' },
    };

    return (
      <div className="image-grid-2-cells-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <Grid2CellBroken>
              <Image image={images[0]} sizes={leftImageSizes} />
              <Image image={images[1]} sizes={rightImageSizes} />
            </Grid2CellBroken>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ImageGrid2Cells;
