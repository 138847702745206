import EventPastGrid from '../../components/EventPastGrid';
import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import { getPastEvents } from '../../utils/content/Events';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  { key: 'showNumbering', definition: controlOptionDefinitions.boolean },
])
class EventPastGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor, showNumbering } = this.controlOptions;

    let events;
    blockContent.doQuery((content) => {
      events = content.getAllOfType(ContentTypeEnum.EVENT);
    });
    getPastEvents(events);

    return (
      <EventPastGrid
        mainHeader={block?.header?.main}
        events={events}
        backgroundColor={backgroundColor}
        showNumbering={showNumbering}
      />
    );
  }
}

export default EventPastGridBlock;
