import { BlockInstanceProps } from '../Block';
import HeaderTextGroup from '../../components/HeaderTextGroup';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'freezeInBreakpoint',
    definition: controlOptionDefinitions.freezeInBreakpoint,
  },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  { key: 'enableRichText', definition: controlOptionDefinitions.boolean },
  { key: 'type', definition: controlOptionDefinitions.headerTextGroupTypes },
])
class HeaderTextGroupBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static types = HeaderTextGroup.types;

  render() {
    const { block } = this.props;

    const { freezeInBreakpoint, backgroundColor, enableRichText, type } =
      this.controlOptions;

    return (
      <HeaderTextGroup
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        link={block?.link}
        type={type}
        freezeInBreakpoint={freezeInBreakpoint}
        enableRichText={enableRichText}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default HeaderTextGroupBlock;
