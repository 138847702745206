import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import ValueCarousel from '../../components/ValuesCarousel';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class ValueCarouselBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getFixedAmountOfType(ContentTypeEnum.MEDIAITEM, 5);
    });

    return (
      <ValueCarousel
        items={mediaItems}
        lightHeader={block?.header?.light}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default ValueCarouselBlock;
