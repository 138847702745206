import { Component } from 'react';
import { css } from '@emotion/react';

import EventUpComing from './EventUpComing';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import Header from '../atoms/Header';

const styles = css({
  '& .event-up-coming': {
    [Breakpoints.TabletDown.mq]: {
      marginTop: '40px',
      marginBottom: '0px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '50px',
      marginBottom: '50px',
    },
  },
});

type EventUpComingListProps = {
  events?: InternalPropTypes.Event[];
  mainHeader?: InternalPropTypes.RichText;
};

class EventUpComingList extends Component<EventUpComingListProps> {
  static defaultProps = {
    events: [],
    mainHeader: '',
  };

  render() {
    const { events, mainHeader } = this.props;

    return (
      <div css={styles}>
        {events && (
          <Header type={Header.types.EVENTHEADER}>{mainHeader}</Header>
        )}

        {events &&
          events.map((event) => (
            <div
              key={`${event.title}-${event.date}`}
              className="event-up-coming"
            >
              <EventUpComing
                title={event.title}
                image={event.image}
                date={event.date}
                geoLocation={event.geoLocation}
                onlineLocation={event.onlineLocation}
                language={event.language}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default EventUpComingList;
