/* eslint-disable react/no-unused-state, class-methods-use-this */
import { Component, FormEvent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { css } from '@emotion/react';

import BodyText from '../atoms/BodyText';
import angledArrow from '../../../assets/angledArrow.png';

import * as InternalPropTypes from '../../../constants/internal-types';
import { colorEnum } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';
import TrackedComponent from '../TrackedComponent';

const styles = css({
  '& .booking-form, .status-message': {
    [Breakpoints.Mobile.mq]: {
      marginTop: '20px',
      maxWidth: '60%',
    },
  },
  '& .booking-form-description': {
    marginBottom: '20px',
  },
  '& .status-message': {
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '50%',
    },
    fontSize: '32px',
    maxWidth: '100%',
  },
  '& .body-text': {
    color: colorEnum.gray,
  },
  '& .message-form-description, .status-message': {
    color: colorEnum.gray,
    marginBottom: '20px',
  },
  '& .name-label, .email-label, .msg-label': {
    color: colorEnum.darkGrey,
    fontSize: '24px',
    '& p': {
      marginBottom: 0,
    },
  },
  '& .name-input, .email-input, .participants-input, .submit-input, .date-picker':
    {
      border: 0,
      outline: 0,
      borderBottom: '1px solid grey',
      color: colorEnum.brightGray,
      backgroundColor: 'transparent',
      marginTop: 0,
      marginBottom: '20px',
      width: '100%',
      fontSize: '18px',
    },
  '& .submit-input': {
    border: 0,
    position: 'absolute',
    right: 0,
  },
  '& .submit-button': {
    [Breakpoints.Mobile.mq]: {
      marginRight: '40%',
    },
    backgroundColor: 'transparent',
    border: 0,
    outline: 0,
    position: 'absolute',
    right: 0,
    display: 'flex',
    '& .submit-arrow': {
      height: '20px',
      width: '25px',
      marginRight: '10px',
      marginTop: '13px',
    },
    '& .submit-text': {
      fontSize: '28px',
      fontWeight: '700',
      color: colorEnum.brightGray,
    },
  },
  '& .react-datepicker-wrapper': {
    width: '100%',
  },
});

type BookingFormProps = {
  description?: InternalPropTypes.RichText;
  submitMessage?: InternalPropTypes.RichText;
  failedSubmitMessage?: InternalPropTypes.RichText;
  submitButtonText?: InternalPropTypes.RichText;
  namePlaceholder?: string;
  emailPlaceholder?: string;
  datePlaceholder?: string;
  participantsPlaceholder?: string;
  sendingMessage?: InternalPropTypes.RichText;
  submit?: (...args: any[]) => Promise<Response>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

interface IBookingFormState {
  // eslint-disable-next-line no-restricted-globals
  name: string;
  email: string;
  date: Date;
  participants: number;
  showForm: boolean;
  successfulSubmit: boolean;
  submitActive: boolean;
}

class BookingForm extends Component<BookingFormProps, IBookingFormState> {
  static defaultProps = {
    description: null,
    submitMessage: null,
    failedSubmitMessage: null,
    namePlaceholder: null,
    emailPlaceholder: null,
    datePlaceholder: null,
    participantsPlaceholder: null,
    submitButtonText: null,
    sendingMessage: null,
    submit: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      date: null,
      participants: 0,
      showForm: true,
      successfulSubmit: false,
      submitActive: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleSubmit(
    e: FormEvent<HTMLFormElement>,
    state: IBookingFormState,
    submit: typeof this.props.submit,
  ) {
    e.preventDefault();
    if (!state.submitActive) {
      this.setState({ showForm: false, submitActive: true });
      submit(
        state.name,
        state.email,
        this.formatDate(state.date),
        state.participants,
      )
        .then((response) => {
          this.setState({ successfulSubmit: response.ok });
        })
        .catch(() => {
          this.setState({ successfulSubmit: false });
        })
        .finally(() => {
          this.setState({ submitActive: false });
        });
    }
  }

  handleDateChange(e) {
    this.setState({ date: e });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleParticipantsChange(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    this.setState({ participants: e.target.value });
  }

  handleClick() {
    this.setState({ showForm: true });
  }

  isWeekday(date) {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  }

  formatDate(date) {
    const day = date.getDate();
    // date.getMonth returns a zero indexed month value (0-11)
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  render() {
    const {
      description,
      submitMessage,
      failedSubmitMessage,
      namePlaceholder,
      emailPlaceholder,
      datePlaceholder,
      participantsPlaceholder,
      submitButtonText,
      submit,
      sendingMessage,
    } = this.props;
    const { state } = this;

    return (
      <TrackedComponent logText="Meeting form seen" trackOncePrSession>
        <div css={styles}>
          {state.showForm && !state.submitActive && (
            <div className="booking-form">
              <BodyText className="booking-form-description">
                {description}
              </BodyText>
              <form
                autoComplete="off"
                onSubmit={(e) => this.handleSubmit(e, state, submit)}
              >
                <input
                  className="name-input"
                  autoComplete="invalid-string"
                  type="text"
                  required
                  placeholder={namePlaceholder}
                  onKeyPress={(e) => this.handleEnter(e)}
                  onChange={(e) => this.handleNameChange(e)}
                />

                <input
                  className="email-input"
                  autoComplete="invalid-string"
                  type="text"
                  pattern="[^@\s]+@[^@\s]+\.+[^@\s]+"
                  required
                  placeholder={emailPlaceholder}
                  onKeyPress={(e) => this.handleEnter(e)}
                  onChange={(e) => this.handleEmailChange(e)}
                />

                <DatePicker
                  className="date-picker"
                  selected={state.date}
                  onChange={this.handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  filterDate={this.isWeekday}
                  showWeekNumbers
                  required
                  placeholderText={datePlaceholder}
                />

                <input
                  className="participants-input"
                  autoComplete="invalid-string"
                  type="text"
                  pattern="[0-9]+"
                  required
                  placeholder={participantsPlaceholder}
                  onKeyPress={(e) => this.handleEnter(e)}
                  onChange={(e) => this.handleParticipantsChange(e)}
                />

                <button type="submit" className="submit-button">
                  <img
                    className="submit-arrow"
                    src={angledArrow}
                    alt="arrow"
                    style={{ filter: 'invert(1)' }}
                  />
                  <BodyText className="submit-text">
                    {submitButtonText}
                  </BodyText>
                </button>
              </form>
            </div>
          )}

          {state.submitActive && (
            <BodyText className="status-message">{sendingMessage}</BodyText>
          )}

          {!state.showForm && state.successfulSubmit && !state.submitActive && (
            <BodyText className="status-message">{submitMessage}</BodyText>
          )}

          {!state.showForm && !state.successfulSubmit && !state.submitActive && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div onClick={() => this.handleClick()}>
              <BodyText className="status-message">
                {failedSubmitMessage}
              </BodyText>
            </div>
          )}
        </div>
      </TrackedComponent>
    );
  }
}

export default BookingForm;
