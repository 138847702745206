import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import SlideShowMolecule from './common/molecules/SlideShow';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';

const styles = {
  '&.slide-show-component': {
    [Breakpoints.Mobile.mq]: {
      paddingTop: '208px',
      paddingBottom: '152px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '120px',
      paddingBottom: '120px',
    },
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '256px',
      paddingBottom: '186px',
    },
  },
};

type SlideShowProps = {
  items?: InternalPropTypes.MediaItem[];
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  backgroundColor?: string;
};

class SlideShow extends Component<SlideShowProps> {
  static defaultProps = {
    items: [],
    header: null,
    lightHeader: null,
    backgroundColor: null,
  };

  render() {
    const { items, header, lightHeader, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="slide-show-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <SlideShowMolecule
              items={items}
              header={header}
              lightHeader={lightHeader}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default SlideShow;
