import { Component } from 'react';

import EventUpComingListMolecule from './common/molecules/EventUpComingList';

import * as InternalPropTypes from '../constants/internal-types';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

const styles = {};

type EventUpComingProps = {
  events?: InternalPropTypes.Event[];
  backgroundColor?: string;
  mainHeader?: InternalPropTypes.RichText;
};

class EventUpComing extends Component<EventUpComingProps> {
  static defaultProps = {
    events: null,
    backgroundColor: null,
    mainHeader: '',
  };

  render() {
    const { events, backgroundColor, mainHeader } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[styles, addStyle]} className="event-up-coming-component">
        <ContentOuterBound>
          <ContentSpacer>
            <EventUpComingListMolecule
              events={events}
              mainHeader={mainHeader}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default EventUpComing;
