import { BlockInstanceProps } from '../Block';
import CueCardGrid from '../../components/CueCardGrid';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CueCardGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <CueCardGrid mediaItems={mediaItems} backgroundColor={backgroundColor} />
    );
  }
}

export default CueCardGridBlock;
