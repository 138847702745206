import { Fragment, Component } from 'react';
import { css } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';

import Image from '../atoms/Image';
import BodyText from '../atoms/BodyText';
import NwLink from '../NwLink';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import { defaultColors } from '../../../constants/colors';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  marginBottom: '40px',
  [Breakpoints.TabletUp.mq]: {
    width: '250px',
    maxHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
  },
  [Breakpoints.Mobile.mq]: {
    maxWidth: '400px',
  },
  '& .item-container': {
    display: 'flex',
    [Breakpoints.TabletUp.mq]: {
      marginTop: '30px',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    '& .item-image': {
      objectFit: 'scale-down',
      [Breakpoints.TabletUp.mq]: {
        flexGrow: 1,
        width: '50%',
        maxHeight: '125px',
        marginLeft: '25%',
      },
      [Breakpoints.Mobile.mq]: {
        height: '50px',
        width: '50px',
        margin: '20px',
      },
    },
    '& .item-text': {
      [Breakpoints.TabletUp.mq]: {
        textAlign: 'center',
        marginTop: '15px',
      },
      [Breakpoints.Mobile.mq]: {
        margin: '20px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});

type BannerItemProps = {
  image?: InternalPropTypes.Image;
  text?: InternalPropTypes.RichText;
  linkTo?: string;
  fontColor?: string;
  openInNewWindowOrTab?: boolean;
};

class BannerItem extends Component<BannerItemProps> {
  static defaultProps = {
    image: '',
    text: '',
    linkTo: '',
    fontColor: defaultColors.bodyText,
    openInNewWindowOrTab: false,
  };

  render() {
    const { image, text, linkTo, fontColor, openInNewWindowOrTab } = this.props;

    const Wrapper = linkTo ? NwLink : Fragment;
    // Have to return empty object for Fragment
    const wrapProps = linkTo ? { to: linkTo, openInNewWindowOrTab } : {};

    const addFontColor = fontColor
      ? { '& .body-text': { color: fontColor } }
      : {};

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '50', h: '50' },
      [ScreenSize.Tablet]: { w: '125', h: '125' },
      [ScreenSize.Desktop]: { w: '125', h: '125' },
    };

    return (
      <BrowserRouter>
        <div className="banner-containingBox" css={[styles, addFontColor]}>
          <section className="item-container">
            <Image className="item-image" image={image} sizes={sizes} />
            <Wrapper {...wrapProps}>
              <div className="item-text">
                <BodyText type={BodyText.types.PROFILEQUOTE}>{text}</BodyText>
              </div>
            </Wrapper>
          </section>
        </div>
      </BrowserRouter>
    );
  }
}

export default BannerItem;
