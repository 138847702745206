import { BlockInstanceProps } from '../Block';
import HeroComponent from '../../components/Hero';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'type',
    definition: controlOptionDefinitions.heroTypes,
  },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class HeroBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { type, backgroundColor } = this.controlOptions;

    let image;
    let video;
    blockContent.doQuery((content) => {
      image = content.getFirstOfType(ContentTypeEnum.IMAGE, false);
      video = content.getFirstOfType(ContentTypeEnum.VIDEO, false);
    });

    return (
      <HeroComponent
        mainHeader={block?.header?.main}
        lightHeader={block?.header?.light}
        bodyText={block?.bodyText}
        image={image}
        video={video}
        type={type}
        link={block?.link}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default HeroBlock;
