import { Component } from 'react';

import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';

const styles = {
  '& .title': {
    marginBottom: '16px',
  },
};
type TitleTextGroupProps = {
  titleText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  type?: TitleTypes;
};

class TitleTextGroup extends Component<TitleTextGroupProps> {
  static types = TitleTypes;

  static defaultProps = {
    titleText: null,
    bodyText: null,
    type: TitleTypes.HERO,
  };

  render() {
    const { titleText, bodyText, type } = this.props;

    return (
      <div className="title-text-group" css={styles}>
        <Title className="title" type={type}>
          {titleText}
        </Title>

        <BodyText type={BodyText.types.STATIC16}>{bodyText}</BodyText>
      </div>
    );
  }
}

export default TitleTextGroup;
