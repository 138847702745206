import { ContentTypeEnum } from '../../constants/cms-constants';
import { BlockInstanceProps } from '../Block';
import EventPromoComponent from '../../components/EventPromo';
import { getFirstComingEvent } from '../../utils/content/Events';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class EventPromo extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let events;
    blockContent.doQuery((content) => {
      events = content.getAllOfType(ContentTypeEnum.EVENT);
    });
    const event = getFirstComingEvent(events);

    return (
      <EventPromoComponent
        promoEvent={event}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default EventPromo;
