import { BlockInstanceProps } from '../Block';
import CasesPageHero from '../../components/CasesPageHero';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CasesHeroBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const { backgroundColor } = this.controlOptions;

    return (
      <CasesPageHero
        header={block?.header?.main}
        lightHeader={block?.header?.light}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default CasesHeroBlock;
