import CallToActionWithHeader from '../../components/CallToActionWithHeader';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'type',
    definition: controlOptionDefinitions.callToActionWithHeaderTypes,
  },
  { key: 'ctaType', definition: controlOptionDefinitions.callToActionTypes },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class CallToActionWithHeaderBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static types = CallToActionWithHeader.types;

  static ctaTypes = CallToActionWithHeader.ctaTypes;

  render() {
    const { block } = this.props;
    const { type, ctaType, backgroundColor } = this.controlOptions;

    return (
      <CallToActionWithHeader
        header={block?.header?.main}
        link={block?.link}
        type={type}
        ctaType={ctaType}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default CallToActionWithHeaderBlock;
