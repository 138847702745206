import React, { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import CallToAction from './common/molecules/CallToAction';
import ContentSpacer from './common/atoms/ContentSpacer';
import BodyText from './common/atoms/BodyText';
import Grid2CellUnevenSplit from './common/atoms/Grid2CellUnevenSplit';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import {
  CallToActionTypeEnum,
  CallToActionWithHeaderTypeEnum,
  TypeCallToActionTypes,
  TypeCallToActionWithHeaderTypes,
} from '../constants/cms-constants';

const styles = {
  paddingBottom: '40px',
};

function getRender(type, header, link, ctaType) {
  return (
    <div className={`cta-with-header-${type}`}>
      {header && (
        <BodyText className="body-text" type={BodyText.types.BOLD16}>
          {header}
        </BodyText>
      )}

      {link && <CallToAction link={link} type={ctaType} />}
    </div>
  );
}

type CallToActionWithHeaderProps = {
  header?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
  type?: TypeCallToActionWithHeaderTypes;
  ctaType?: TypeCallToActionTypes;
  backgroundColor?: string;
};

class CallToActionWithHeader extends Component<CallToActionWithHeaderProps> {
  static ctaTypes = CallToAction.types;

  static types = CallToActionWithHeaderTypeEnum;

  static defaultProps = {
    header: null,
    link: null,
    type: CallToActionWithHeaderTypeEnum.LEFT,
    ctaType: CallToActionTypeEnum.REGULAR,
    backgroundColor: null,
  };

  render() {
    const { header, link, type, ctaType, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    // eslint-disable-next-line react/jsx-no-useless-fragment
    let result = <></>;
    if (type === CallToActionWithHeaderTypeEnum.LEFT) {
      result = (
        <div className="call-to-action-with-header" css={[styles, addStyle]}>
          <ContentOuterBound>
            <ContentSpacer>
              <Grid2CellUnevenSplit
                leftCellCount={5}
                rightCellCount={5}
                onRenderCell1={() => getRender(type, header, link, ctaType)}
              />
            </ContentSpacer>
          </ContentOuterBound>
        </div>
      );
    }
    if (type === CallToActionWithHeaderTypeEnum.RIGHT) {
      result = (
        <div className="call-to-action-with-header" css={[styles]}>
          <ContentOuterBound>
            <ContentSpacer>
              <Grid2CellUnevenSplit
                snapAtBreakpoint={Breakpoints.MobileDown}
                leftCellCount={4}
                rightCellCount={7}
                onRenderCell2={() => getRender(type, header, link, ctaType)}
              />
            </ContentSpacer>
          </ContentOuterBound>
        </div>
      );
    }

    return result;
  }
}

export default CallToActionWithHeader;
