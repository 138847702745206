import { Component } from 'react';
import { css } from '@emotion/react';

import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';
import Hstack3CellExtremity from '../atoms/Hstack3CellExtremity';
import Caption from '../atoms/Caption';
import Image from '../atoms/Image';
import NwLink from '../NwLink';

import { getStringFromRichText } from '../../../utils/content/RichTextConverter';

import font from '../../../constants/fonts';
import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const styles = css({
  marginBottom: '80px',
  '& .image-container': {
    overflow: 'hidden',
    marginBottom: '16px',
  },
  '& .light-header': {
    opacity: '0.5',
  },
  '& :hover': {
    '& .case-title': {
      textDecoration: 'underline',
    },
    '& .image': {
      transform: 'scale(1.05)',
      transition: 'all 200ms linear',
    },
  },
  '& .image': {
    transform: 'scale(1.0)',
    transition: 'all 200ms linear',
    display: 'block',
    width: '-webkit-fill-available',
    objectFit: 'cover',
    [Breakpoints.TabletDown.mq]: {
      maxHeight: '296px',
    },
    [Breakpoints.DesktopUp.mq]: {
      maxHeight: '368px',
    },
  },
  '& .text': {
    width: '100%',
    '& .teaser': {
      ...font.GNBold,
    },
    '& .body-text': {
      maxWidth: '80%',
    },
  },
});

type StylingOptions = {
  paddingRight?: string;
  paddingLeft?: string;
  marginRight?: string;
  marginLeft?: string;
  height?: string;
};

type TeaserProps = {
  image?: InternalPropTypes.Image;
  title?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  text?: InternalPropTypes.RichText;
  number?: number;
  showNumbering?: boolean;
  link?: InternalPropTypes.Link;
  styling?: StylingOptions;
};

export const getImageSizesFromTeaserGridStyling = (styling: StylingOptions) => {
  const pxStrToNumberOrZero = (str: string) =>
    Number(str?.replace('px', '') || 0);

  const { paddingLeft, paddingRight, marginLeft, marginRight, height } =
    styling;

  const baseWidth = {
    [ScreenSize.Mobile]: 743,
    [ScreenSize.Tablet]: 452,
    [ScreenSize.Desktop]: 500,
  };
  const widthToSubtract =
    pxStrToNumberOrZero(paddingLeft) +
    pxStrToNumberOrZero(paddingRight) +
    pxStrToNumberOrZero(marginLeft) +
    pxStrToNumberOrZero(marginRight);

  // Omit height if it could not be derived
  const formattedHeight = pxStrToNumberOrZero(height).toString() || undefined;

  const sizes: SizesType = {
    [ScreenSize.Mobile]: {
      w: baseWidth[ScreenSize.Mobile].toString(),
    },
    [ScreenSize.Tablet]: {
      w: `${baseWidth[ScreenSize.Tablet] - widthToSubtract}`,
      h: formattedHeight,
    },
    [ScreenSize.Desktop]: {
      w: `${baseWidth[ScreenSize.Desktop] - widthToSubtract}`,
      h: formattedHeight,
    },
  };

  return sizes;
};

class Teaser extends Component<TeaserProps> {
  static defaultProps = {
    image: null,
    title: null,
    lightHeader: null,
    text: null,
    number: 0,
    showNumbering: false,
    link: { linkTo: '' },
    styling: {
      paddingRight: null,
      paddingLeft: null,
      marginRight: null,
      marginLeft: null,
      height: '256px',
    },
  };

  render() {
    const {
      image,
      title,
      text,
      number,
      showNumbering,
      link,
      styling,
      lightHeader,
    } = this.props;

    const label =
      (lightHeader ? `${getStringFromRichText(lightHeader)} ` : '') +
      (title ? getStringFromRichText(title) : '');

    const sizes = getImageSizesFromTeaserGridStyling(styling);

    return (
      <div
        className="case-teaser"
        css={[
          styles,
          {
            paddingLeft: styling.paddingLeft ? styling.paddingLeft : '0',
            paddingRight: styling.paddingRight ? styling.paddingRight : '0',
            marginLeft: styling.marginLeft ? styling.marginLeft : '0',
            marginRight: styling.marginRight ? styling.marginRight : '0',
          },
        ]}
      >
        <NwLink
          to={link.linkTo}
          css={{ '&:hover': { textDecoration: 'none' } }}
          aria-label={label}
          openInNewWindowOrTab={link.openInNewWindowOrTab}
        >
          <div className="image-container" css={{ height: styling.height }}>
            <Image className="image" image={image} sizes={sizes} />
          </div>
          <div className="text">
            <Caption type={Caption.types.NAVIGATION} className="light-header">
              {lightHeader}
            </Caption>
            <Hstack3CellExtremity
              onRenderLeftCell={() => (
                <Title as="p" className="case-title" type={TitleTypes.TEASER}>
                  {title}
                </Title>
              )}
              onRenderRightCell={() =>
                showNumbering ? (
                  <BodyText type={BodyText.types.STATIC14}>
                    {`${number.toString().length > 1 ? '.' : '.0'}${number}`}
                  </BodyText>
                ) : null
              }
            />
            <BodyText type={BodyText.types.STATIC14} css={{ marginTop: '8px' }}>
              {text}
            </BodyText>
          </div>
        </NwLink>
      </div>
    );
  }
}

export default Teaser;
