import { Component } from 'react';
import { css } from '@emotion/react';

import Image from '../atoms/Image';
import Caption from '../atoms/Caption';
import BodyText from '../atoms/BodyText';

import * as InternalPropTypes from '../../../constants/internal-types';
import { ImgixParams } from '../../../types/ts/imgixQuery';

const styles = css({
  '& .title-author': {
    textTransform: 'uppercase',
    opacity: '0.5',
  },
  '& .info': {
    marginTop: '16px',
    display: 'flex',
    '& .image': {
      height: '40px',
      width: '40px',
      objectFit: 'cover',
      borderRadius: '100%',
      marginRight: '12px',
    },
    '& .text:': {
      alignItem: 'center',
    },
    '& .book12:last-child': {
      opacity: '0.5',
    },
    '& .body-text p': {
      marginBottom: 0,
    },
  },
  '& .description': { whiteSpace: 'nowrap' },
});

type ProfileAuthorProps = {
  // eslint-disable-next-line no-restricted-globals
  name?: string;
  jobTitle?: string;
  description?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  className?: string;
};

class ProfileAuthor extends Component<ProfileAuthorProps> {
  static defaultProps = {
    name: '',
    jobTitle: '',
    description: null,
    image: null,
    className: '',
  };

  render() {
    const { name, jobTitle, image, description, className } = this.props;

    const sizes: ImgixParams = { w: '40', h: '40' };

    return (
      <div css={styles} className={className}>
        <Caption type={Caption.types.TITLEAUTHOR} className="description">
          {description}
        </Caption>
        <div className="info">
          <Image image={image} sizes={sizes} />
          <div className="text">
            <BodyText type={BodyText.types.BOOK12}>{name}</BodyText>
            <BodyText type={BodyText.types.BOOK12}>{jobTitle}</BodyText>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileAuthor;
