import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import Image from './common/atoms/Image';
import GridEvenSplit from './common/atoms/GridEvenSplit';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import ScreenSize from '../constants/screenSize';
import { SizesType } from '../types/ts/imgixQuery';

const styles = {
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '70px',
    paddingBottom: '70px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '35px',
    paddingBottom: '35px',
  },
  '& .image': {
    height: '100%',
    width: '100%',
  },
};

type ImageGrid3EvenCellsProps = {
  images?: InternalPropTypes.Image[];
  backgroundColor?: string;
};

class ImageGrid3EvenCells extends Component<ImageGrid3EvenCellsProps> {
  static defaultProps = {
    images: [],
    backgroundColor: null,
  };

  render() {
    const { images, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '743', fit: 'clip' },
      [ScreenSize.Tablet]: { w: '301', h: '301', fit: 'clip' },
      [ScreenSize.Desktop]: { w: '333', h: '467', fit: 'clip' },
    };

    return (
      <div className="image-grid-3-cells-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <GridEvenSplit
              onRenderCells={[
                () => <Image image={images[0]} sizes={sizes} />,
                () => <Image image={images[1]} sizes={sizes} />,
                () => <Image image={images[2]} sizes={sizes} />,
              ]}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ImageGrid3EvenCells;
