import { BlockInstanceProps } from '../Block';
import HorizontalLine from '../../components/HorizontalLine';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class HorizontalLineBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { backgroundColor } = this.controlOptions;

    return <HorizontalLine backgroundColor={backgroundColor} />;
  }
}

export default HorizontalLineBlock;
