import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ValueCarouselMolecule from './common/molecules/ValueCarousel';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import { colorEnum } from '../constants/colors';

const styles = {
  [Breakpoints.TabletDown.mq]: {
    marginTop: '64px',
    paddingTop: '64px',
    paddingBottom: '64px',
  },
  [Breakpoints.DesktopUp.mq]: {
    marginTop: '70px',
    paddingTop: '100px',
    paddingBottom: '100px',
  },
};

type ValuesCarouselProps = {
  lightHeader?: InternalPropTypes.RichText;
  backgroundColor?: string;
  items?: InternalPropTypes.MediaItem[];
};

class ValuesCarousel extends Component<ValuesCarouselProps> {
  static defaultProps = {
    lightHeader: null,
    backgroundColor: colorEnum.lightGray,
    items: [],
  };

  render() {
    const { lightHeader, items, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <ValueCarouselMolecule lightHeader={lightHeader} items={items} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ValuesCarousel;
