import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import WellFull from '../../components/WellFull';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'type',
    definition: controlOptionDefinitions.wellTypes,
  },
  { key: 'position', definition: controlOptionDefinitions.positionTypes },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class WellFullSizeBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { type, position, backgroundColor } = this.controlOptions;

    let image;
    blockContent.doQuery((content) => {
      image = content.getFirstOfType(ContentTypeEnum.IMAGE);
    });

    return (
      <WellFull
        image={image}
        link={block?.link}
        backgroundColor={backgroundColor}
        type={type}
        position={position}
      />
    );
  }
}

export default WellFullSizeBlock;
