/* eslint-disable react/prop-types, max-classes-per-file */
import React, { Component, ComponentProps, ErrorInfo } from 'react';
import { css } from '@emotion/react';

import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';

import ErrorInput from '../reporterInput/ErrorInput';
import { SeverityLevels } from '../../constants/reporterServiceInputs';
import { withReporterServiceContextProps } from './withReporterService';
import { WithAppConfigProps } from './withAppConfig';

const styles = css({
  marginTop: '50px',
  paddingTop: '50px',
  paddingBottom: '50px',
  backgroundColor: '#FEFF72',
});

interface IAppErrorBoundaryState {
  error: Error;
  errorInfo: ErrorInfo;
}

type AppErrorBoundaryProps = withReporterServiceContextProps;

export function withAppErrors(WrappedComponent) {
  return class AppErrorBoundary extends Component<
    AppErrorBoundaryProps & ComponentProps<typeof WrappedComponent>,
    IAppErrorBoundaryState
  > {
    // eslint-disable-next-line react/state-in-constructor
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        errorInfo: null,
      };
    }

    componentDidCatch(error, errorInfo) {
      const { reporterService } = this.props;
      if (reporterService) {
        const err = ErrorInput.createFromException(error);
        err.severityLevel = SeverityLevels.CRITICAL;
        reporterService.reportError(err);
      }
      this.setState({
        error,
        errorInfo,
      });
    }

    render() {
      const { error, errorInfo } = this.state;
      // eslint-disable-next-line react/jsx-filename-extension
      let render: JSX.Element = <WrappedComponent {...this.props} />;
      if (error) {
        if (process.env.NODE_ENV !== 'production') {
          render = (
            <div className="error-message" css={styles}>
              <ContentOuterBound>
                <h2>Something went wrong.</h2>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  {error.toString()}
                  <br />
                  {errorInfo.componentStack}
                </details>
              </ContentOuterBound>
            </div>
          );
        } else {
          render = null;
        }
      }

      return render;
    }
  };
}

type BlockErrorBoundaryProps = WithAppConfigProps;

interface IBlockErrorBoundaryState {
  error: Error;
  errorInfo: any;
}

export function withBlockErrors(WrappedComponent) {
  return class BlockErrorBoundary extends Component<
    BlockErrorBoundaryProps & ComponentProps<typeof WrappedComponent>,
    IBlockErrorBoundaryState
  > {
    constructor(props) {
      super(props);

      this.state = {
        error: null,
        errorInfo: null,
      };
    }

    componentDidCatch(error, errorInfo) {
      const { reporterService } = this.props.appConfig.services;
      if (reporterService) {
        const err = ErrorInput.createFromException(error);
        err.severityLevel = SeverityLevels.WARNING;
        reporterService.reportError(err);
      }
      this.setState({
        error,
        errorInfo,
      });
    }

    render() {
      const { error, errorInfo } = this.state;
      // eslint-disable-next-line react/jsx-filename-extension
      let render: JSX.Element = <WrappedComponent {...this.props} />;
      if (error) {
        if (process.env.NODE_ENV !== 'production') {
          render = (
            <div className="error-message" css={styles}>
              <ContentOuterBound>
                <h2>Something went wrong.</h2>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  {error.toString()}
                  <br />
                  {errorInfo.componentStack}
                </details>
              </ContentOuterBound>
            </div>
          );
        } else {
          render = null;
        }
      }

      return render;
    }
  };
}
