import { Component } from 'react';

import Grid3CellTabletUp2CellMobile from './common/molecules/Grid3CellTabletUp2CellMobile';
import ProfileBase from './common/molecules/ProfileBase';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';
import {
  ProfileGridEnum,
  TypeProfileGridTypes,
} from '../constants/cms-constants';

const styles = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '44px',
    paddingBottom: '44px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '56px',
    paddingBottom: '56px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '44px',
    paddingBottom: '44px',
  },
};

function getRender(type) {
  switch (type) {
    case ProfileGridEnum.ALL:
      return function (item, idx) {
        return (
          <div key={`${item.name}-${idx}`}>
            <ProfileBase {...item} />
          </div>
        );
      };
    case ProfileGridEnum.DESCRIPTION:
      return function (item, idx) {
        return (
          <div key={`${item.name}-${idx}`}>
            <ProfileBase
              name={item.name}
              jobTitle={item.jobTitle}
              image={item.image}
              description={item.description}
              link={item.link}
            />
          </div>
        );
      };
    case ProfileGridEnum.CONTACT_INFORMATION:
      return function (item, idx) {
        return (
          <div key={`${item.name}-${idx}`}>
            <ProfileBase
              name={item.name}
              jobTitle={item.jobTitle}
              image={item.image}
              phone={item.phone}
              email={item.email}
              link={item.link}
            />
          </div>
        );
      };
    case ProfileGridEnum.BASE:
      return function (item, idx) {
        return (
          <div key={`${item.name}-${idx}`}>
            <ProfileBase
              name={item.name}
              jobTitle={item.jobTitle}
              image={item.image}
              link={item.link}
            />
          </div>
        );
      };
    default:
      return function (item, idx) {
        return (
          <div key={`${item.name}-${idx}`}>
            <ProfileBase
              name={item.name}
              jobTitle={item.jobTitle}
              image={item.image}
              phone={item.phone}
              email={item.email}
              link={item.link}
            />
          </div>
        );
      };
  }
}

type ProfileGridProps = {
  persons?: InternalPropTypes.Person[];
  profileGridType?: TypeProfileGridTypes;
  backgroundColor?: string;
};

class ProfileGrid extends Component<ProfileGridProps> {
  static profileGridTypes = ProfileGridEnum;

  static defaultProps = {
    persons: null,
    profileGridType: this.profileGridTypes.CONTACT_INFORMATION,
    backgroundColor: null,
  };

  render() {
    const { persons, backgroundColor, profileGridType } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    const renderInput = getRender(profileGridType);

    return (
      <div css={[styles, addStyle]} className="profile-grid-component">
        <ContentOuterBound>
          <ContentSpacer>
            <Grid3CellTabletUp2CellMobile snapAtBreakpoint={null}>
              {persons.map((person, idx) => renderInput(person, idx))}
            </Grid3CellTabletUp2CellMobile>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default ProfileGrid;
