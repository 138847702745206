import * as CookieConstants from '../client-server-utils/Cookie';
import { CONSENTCATEGORIES, consentEnum } from '../constants/consentCategories';

function consentIsValid(consentString) {
  if (!consentString) {
    return false;
  }
  // If consent is of the "old" version, all categories are accepted
  // If consent is of the new version, we should read the individual categories
  // If version have been updated by the editors, consent should be updated
  if (consentString === CookieConstants.COOKIE_CONSENT_VALUE) {
    return true;
  }

  if (consentString !== undefined && consentString.startsWith('{')) {
    const parsedConsentCookie = JSON.parse(consentString);
    if (parsedConsentCookie.version === CookieConstants.COOKIE_CONSENT_VALUE) {
      return true;
    }
  }

  return false;
}

export default class Consent {
  private version: string | Date;

  constructor(consentString?: string) {
    if (!consentString) {
      this.version = CookieConstants.COOKIE_CONSENT_VALUE;
      this.setNecessaryCookieConsent();
    } else if (consentString.startsWith('{')) {
      const parsedConsentCookie = JSON.parse(consentString);
      Object.assign(this, parsedConsentCookie);
    } else {
      this.version = consentString;
      this.setAllCookieConsent();
    }
  }

  static initializeConsent(consentString: string) {
    let consent = null;
    if (consentIsValid(consentString)) {
      consent = new Consent(consentString);
    }

    let resolveConsentFunction = null;

    const consentPromise = new Promise<Consent>((resolve, reject) => {
      try {
        if (consent) {
          resolve(consent);
        } else {
          resolveConsentFunction = resolve;
        }
      } catch (e) {
        reject(e);
      }
    });

    return { consent, consentPromise, resolveConsentFunction };
  }

  private setNecessaryCookieConsent() {
    CONSENTCATEGORIES.forEach((item) => {
      this[item.key] = item.key === consentEnum.NECESSARY;
    });
  }

  private setAllCookieConsent() {
    CONSENTCATEGORIES.forEach((item) => {
      this[item.key] = true;
    });
  }

  public updateCookieConsent(key, value) {
    this[key] = value;
    return this;
  }

  public getCookieString() {
    return JSON.stringify(this);
  }
}
