enum TitleTypes {
  SECTION = 'section',
  SUMMARY = 'summary',
  GRIDHEADER = 'grid-header',
  GRIDSUMMARY = 'grid-summary',
  CONTACT = 'contact',
  CASE = 'case',
  CASES = 'cases',
  TEASER = 'teaser',
  BUZZWORD = 'buzzword',
  NAVIGABLE = 'navigable',
  NAVILIST = 'navi-list',
  LEAD = 'lead',
  NEXT = 'next',
  HERO = 'hero',
  EVENTTITLE = 'event-title',
}

export default TitleTypes;
