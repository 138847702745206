import { Component } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';

import RichTextWrapper from '../RichTextWrapper';
import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
// eslint-disable-next-line import/no-cycle

import { colorEnum, defaultColors } from '../../../constants/colors';
import font from '../../../constants/fonts';

const useStyles = css({
  padding: '0',
  margin: '0',
  color: defaultColors.header,
  '&.page': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '48px',
      letterSpacing: '-0.92px',
      lineHeight: '48px',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '70px',
      letterSpacing: '-1.35px',
      lineHeight: '72px',
    },
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '80px',
      letterSpacing: '-1.54px',
      lineHeight: '80px',
    },
  },
  '&.big': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '64px',
      letterSpacing: '-1.3px',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '96px',
      letterSpacing: '-1.95px',
    },
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '112px',
      letterSpacing: '-2.27px',
    },
  },
  '&.hero': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '40px',
      letterSpacing: '-0.92px',
      lineHeight: '40px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '64px',
      letterSpacing: '-1.2px',
      lineHeight: '64px',
    },
  },
  '&.hero2': {
    ...font.GNBold,
    fontSize: '32px',
    lineHeight: '38px',
  },
  '&.antihero': {
    ...font.GNBold,
    fontSize: '81px',
  },
  '&.antihero2': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '34px',
      lineHeight: '36px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '48px',
      lineHeight: '38px',
    },
  },
  '&.blackface': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      lineHeight: '54px',
    },
    [Breakpoints.TabletDown.mq]: {
      fontSize: '64px',
      lineHeight: '80px',
      letterSpacing: '-1.15px',
    },
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '72px',
      lineHeight: '80px',
      letterSpacing: '-1.47px',
    },
  },
  '&.case': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '32px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '48px',
      lineHeight: '38px',
    },
  },
  '&.case-header': {
    ...font.GNBook,
    [Breakpoints.Mobile.mq]: {
      fontSize: '21px',
      lineHeight: '24px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
  '&.case-sub-header': {
    ...font.GNBook,
    [Breakpoints.Mobile.mq]: {
      fontSize: '21px',
      lineHeight: '24px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
  '&.partner': {
    ...font.GNBook,
    fontSize: '32px',
    letterSpacing: '-0.27px',
    lightHeight: '32px',
  },
  '&.contact': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '48px',
      letterSpacing: '-1px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '72px',
      letterSpacing: '-1.5px',
    },
  },
  '&.cue': {
    ...font.GNBook,
    fontSize: '24px',
    letterSpacing: '-0.2px',
  },
  '&.key-figure': {
    ...font.GNBook,
    fontWeight: '700',
    fontSize: '40px',
    lineHeight: '48px',
  },
  '&.name-base': {
    ...font.GNBook,
    lightHeight: '32px',
    letterSpacing: '-0.15px',
    [Breakpoints.Mobile.mq]: {
      fontSize: '18px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '24px',
    },
  },
  '&.name-case': {
    ...font.GNLight,
    lightHeight: '32px',
    [Breakpoints.TabletDown.mq]: {
      fontSize: '20px',
      letterSpacing: '-0.17px',
    },
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '24px',
      letterSpacing: '-0.2px',
    },
  },
  '&.name-contact': {
    ...font.GNBold,
    fontSize: '24px',
    lineHeight: '28px',
  },
  '&.footer': {
    ...font.GNMedium,
    fontSize: '32px',
    lineHeight: '38px',
  },
  '&.slide-show-progress': {
    ...font.GNBook,
    fontSize: '16px',
    letterSpacing: '-0.13px',
    lineHeight: '32px',
  },
  '&.header-text-group-large-text': {
    ...font.GNBook,
    fontSize: '32px',
    lineHeight: '32px',
  },
  '&.header-text-group-wide': {
    ...font.GNBook,
    lineHeight: '28px',
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '24px',
      letterSpacing: '-0.2px',
    },
    [Breakpoints.TabletDown.mq]: {
      fontSize: '21px',
      letterSpacing: '-0.15px',
    },
  },
  '&.header-text-group-narrow': {
    ...font.GNBook,
    fontSize: '24px',
    lineHeight: '32px',
  },
  '&.article': {
    ...font.GNBold,
    fontSize: '32px',
    lineHeight: '34px',
  },
  '&.values': {
    ...font.GNBold,
    [Breakpoints.DesktopUp.mq]: {
      fontSize: '128px',
      lineHeight: '80px',
      letterSpacing: '-2.46',
    },
    [Breakpoints.Tablet.mq]: {
      fontSize: '104px',
      letterSpacing: '-2',
    },
    [Breakpoints.Mobile.mq]: {
      fontSize: '56px',
      lineHeight: '48px',
      letterSpacing: '-1.08',
    },
  },
  '&.text-box-h2': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '34px',
      lineHeight: '36px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '48px',
      lineHeight: '38px',
    },
  },
  '&.text-box-h3': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '28px',
      lineHeight: '30px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '36px',
      lineHeight: '34px',
    },
  },
  '&.text-box-h4': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '24px',
      lineHeight: '48px',
    },
  },
  '&.text-box-h5': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '21px',
      lineHeight: '25px',
    },
  },
  '&.text-box-h6': {
    ...font.GNBold,
    [Breakpoints.Mobile.mq]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [Breakpoints.TabletUp.mq]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  '&.event-promo': {
    ...font.GNBold,
    color: colorEnum.white,
    marginRight: '0px',
    fontSize: '20px',
    marginTop: '20px',
    [Breakpoints.TabletUp.mq]: {
      fontSize: '33px',
      marginRight: '60px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '50px',
    },
  },
  '&.event-header': {
    ...font.GNBold,
    fontSize: '14px',
    marginBottom: '0px',
    [Breakpoints.Tablet.mq]: {
      fontSize: '30px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '50px',
      fontSize: '30px',
    },
  },
});

export enum HeaderEnum {
  HERO = 'hero',
  HERO2 = 'hero2',
  ANTIHERO = 'antihero', // similar to the hero, but invisible to SEO
  ANTIHERO2 = 'antihero2', // similar to textboxh2, but invisible to SEO
  PAGE = 'page',
  BIG = 'big',
  BLACKFACE = 'blackface',
  CASE = 'case',
  CASEHEADER = 'case-header',
  CASESUBHEADER = 'case-sub-header',
  PARTNER = 'partner',
  CONTACT = 'contact',
  CUE = 'cue',
  KEYFIGURE = 'key-figure',
  FOOTER = 'footer',
  NAMEBASE = 'name-base',
  NAMECASE = 'name-case',
  NAMECONTACT = 'name-contact',
  SLIDESHOWPROGRESS = 'slide-show-progress',
  HEADERTEXTGROUPLARGETEXT = 'header-text-group-large-text',
  HEADERTEXTGROUPWIDE = 'header-text-group-wide',
  HEADERTEXTGROUPNARROW = 'header-text-group-narrow',
  ARTICLE = 'article',
  VALUES = 'values',
  TEXTBOXH2 = 'text-box-h2',
  TEXTBOXH3 = 'text-box-h3',
  TEXTBOXH4 = 'text-box-h4',
  TEXTBOXH5 = 'text-box-h5',
  TEXTBOXH6 = 'text-box-h6',
  EVENTPROMO = 'event-promo',
  EVENTHEADER = 'event-header',
}

const typeToTag = (type: string) => {
  switch (type) {
    case HeaderEnum.ANTIHERO: // similar to the hero, but invisible to SEO
      return 'p';
    case HeaderEnum.ANTIHERO2: // similar to textboxh2, but invisible to SEO
      return 'p';
    case HeaderEnum.BIG:
      return 'p';
    case HeaderEnum.CASESUBHEADER:
      return 'h2';
    case HeaderEnum.CUE:
      return 'h3';
    case HeaderEnum.BLACKFACE:
      return 'h2';
    case HeaderEnum.FOOTER:
      return 'h2';
    case HeaderEnum.TEXTBOXH2:
      return 'h2';
    case HeaderEnum.TEXTBOXH3:
      return 'h3';
    case HeaderEnum.TEXTBOXH4:
      return 'h4';
    case HeaderEnum.TEXTBOXH5:
      return 'h5';
    case HeaderEnum.TEXTBOXH6:
      return 'h6';
    default:
      return 'h1';
  }
};

type HeaderProps = {
  type?: `${HeaderEnum}`;
  className?: string;
  css?: Interpolation<Theme>;
  children?: InternalPropTypes.StringOrRichTextOrElementOrNestedArrays;
};

class Header extends Component<HeaderProps> {
  static types = HeaderEnum;

  static defaultProps = {
    type: HeaderEnum.PAGE,
    className: undefined,
    css: null,
    children: null,
  };

  render() {
    const { children, type, css, className } = this.props;

    let output: React.ReactNode;
    if (InternalPropTypes.isRichText(children)) {
      output = (
        <RichTextWrapper
          richText={children as InternalPropTypes.RichText}
          renderFunction={RichTextRenderer.formattedTextOnly}
        />
      );
    } else {
      output = children as InternalPropTypes.NestedArraysWithElementOrString;
    }
    const Tag = typeToTag(type);
    const addStyles = css || {};
    return (
      <Tag
        css={[useStyles, addStyles]}
        className={`header-text ${type}${className ? ` ${className}` : ''}`}
      >
        {output}
      </Tag>
    );
  }
}

export default Header;
