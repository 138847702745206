import { BlockInstanceProps } from '../Block';
import SectionTop from '../../components/SectionTop';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'freezeInBreakpoint',
    definition: controlOptionDefinitions.freezeInBreakpoint,
  },
  {
    key: 'long',
    definition: controlOptionDefinitions.boolean,
  },
])
class SectionTopBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block } = this.props;
    const { backgroundColor, freezeInBreakpoint, long } = this.controlOptions;

    return (
      <SectionTop
        lightHeaderText={block?.header?.light}
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        link={block?.link}
        long={long}
        freezeInBreakpoint={freezeInBreakpoint}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default SectionTopBlock;
