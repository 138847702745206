import { Component } from 'react';

import BodyText from './BodyText';

type IndexSpinnerProps = {
  currentIndex?: number;
  maxIndex?: number;
};

class IndexSpinner extends Component<IndexSpinnerProps> {
  static defaultProps = {
    maxIndex: 0,
    currentIndex: 0,
  };

  render() {
    let { currentIndex } = this.props;
    const { maxIndex } = this.props;
    currentIndex = Math.min(currentIndex, maxIndex);
    const numDigitsMax = Math.max(Math.floor(Math.log10(maxIndex)) + 1, 1);
    const numDigitsCur = Math.max(Math.floor(Math.log10(currentIndex)) + 1, 1);
    const formattedCurIdx =
      '0'.repeat(Math.max(numDigitsMax - numDigitsCur, 1)) + currentIndex;
    const formattedMaxIdx = '0'.repeat(Math.max(numDigitsMax, 1)) + maxIndex;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <BodyText type={BodyText.types.STATIC12} as="span">
        {formattedCurIdx} -{formattedMaxIdx}
      </BodyText>
    );
  }
}

export default IndexSpinner;
