import { Component } from 'react';
import { css } from '@emotion/react';

import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';
import CallToAction from './CallToAction';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';
import Caption from '../atoms/Caption';

import * as Breakpoints from '../../../constants/breakpoints';
import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';

const styles = css({
  '& .container': {
    paddingLeft: '0',
  },
  '& .cell': {
    [Breakpoints.Mobile.mq]: {
      maxWidth: '100%',
      flex: '0 0 100%',
    },
  },
  '& .heavy': {
    textTransform: 'uppercase',
    marginBottom: '34px',
  },
  '& .buzzword': {
    marginBottom: '10px',
  },
  '& .cta': {
    [Breakpoints.Mobile.mq]: {
      marginTop: '52px',
    },
    [Breakpoints.Tablet.mq]: {
      marginTop: '36px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginTop: '48px',
    },
  },
  '& .body-text': {
    [Breakpoints.Mobile.mq]: {
      marginTop: '72px',
    },
    [Breakpoints.TabletUp.mq]: {
      marginTop: '4px',
    },
  },
});

type BuzzListProps = {
  header?: InternalPropTypes.RichText;
  buzzWords?: InternalPropTypes.RichText[];
  text?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
};

class BuzzList extends Component<BuzzListProps> {
  static defaultProps = {
    header: null,
    buzzWords: [],
    text: null,
    link: null,
  };

  render() {
    const { buzzWords, header, text, link } = this.props;

    return (
      <div css={styles}>
        <Caption type={Caption.types.HEAVY}>{header}</Caption>

        <Grid2CellUnevenSplit
          leftCellCount={4}
          rightCellCount={7}
          snapAtBreakpoint={Breakpoints.Mobile}
          className="container"
          onRenderCell1={() =>
            buzzWords.map((buzzWord, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Title key={index} type={TitleTypes.BUZZWORD}>
                {buzzWord}
              </Title>
            ))
          }
          onRenderCell2={() => (
            <div>
              <BodyText type={BodyText.types.STATIC16}>{text}</BodyText>
              {link && <CallToAction link={link} />}
            </div>
          )}
        />
      </div>
    );
  }
}

export default BuzzList;
