import { BlockInstanceProps } from '../Block';
import ImageGrid3EvenCells from '../../components/ImageGrid3EvenCells';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class ImageGrid3EvenCellsBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let images;
    blockContent.doQuery((content) => {
      images = content.getFixedAmountOfType(ContentTypeEnum.IMAGE, 3);
    });

    return (
      <ImageGrid3EvenCells images={images} backgroundColor={backgroundColor} />
    );
  }
}

export default ImageGrid3EvenCellsBlock;
