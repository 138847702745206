import EventUpComing from '../../components/EventUpComing';
import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import { getComingEvents } from '../../utils/content/Events';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class EventUpComingBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let events;

    blockContent.doQuery((content) => {
      events = content.getAllOfType(ContentTypeEnum.EVENT);
    });
    getComingEvents(events);

    return (
      <EventUpComing
        mainHeader={block?.header?.main}
        events={events}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default EventUpComingBlock;
