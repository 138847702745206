import React, { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import NavigableListMolecule from './common/molecules/NavigableList';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';

const styles = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '64px',
    marginBottom: '100px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '64px',
  },
};

type NavigableListProps = {
  content?: InternalPropTypes.MediaItem[];
  backgroundColor?: string;
};

class NavigableList extends Component<NavigableListProps> {
  static defaultProps = {
    content: [],
    backgroundColor: null,
  };

  render() {
    const { content, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="nav-list-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <NavigableListMolecule content={content} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default NavigableList;
