import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import CasesHero from './common/molecules/CasesHero';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';

const styles = {
  '&.cases-page-hero-component': {
    [Breakpoints.DesktopUp.mq]: {
      paddingTop: '154px',
    },
    [Breakpoints.Tablet.mq]: {
      paddingTop: '142px',
    },
    [Breakpoints.Mobile.mq]: {
      paddingTop: '112px',
    },
  },
};

type FrontPageHeroProps = {
  header?: InternalPropTypes.RichText;
  lightHeader?: InternalPropTypes.RichText;
  backgroundColor?: string;
};

class FrontPageHero extends Component<FrontPageHeroProps> {
  static defaultProps = {
    header: null,
    lightHeader: null,
    backgroundColor: null,
  };

  render() {
    const { header, lightHeader, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="cases-page-hero-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <CasesHero header={header} lightHeader={lightHeader} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default FrontPageHero;
