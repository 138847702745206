import { Component } from 'react';

import Caption from '../atoms/Caption';
import Title from '../atoms/Title';
import BodyText from '../atoms/BodyText';
import NwLink from '../NwLink';
import RichTextWrapper from '../RichTextWrapper';

import RichTextRenderer from '../../../utils/content/RichTextRenderer';

import * as InternalPropTypes from '../../../constants/internal-types';
import TitleTypes from '../../../constants/title-types';

const useStyles = {
  maxWidth: '300px',
  '& .caption': {
    marginBottom: '24px',
    textTransform: 'uppercase',
  },
  '& .clickable-area': {
    ':hover': {
      textDecoration: 'none',
      '& .contact': {
        opacity: '0.72',
      },
    },
  },
  '& .next-article-body-text': {
    marginTop: '24px',
  },
};
type NextArticleProps = {
  url?: string;
  header?: InternalPropTypes.RichText;
  teaserTitle?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText | string;
  backgroundColor?: string;
  className?: string;
  openInNewWindowOrTab?: boolean;
};
class NextArticle extends Component<NextArticleProps> {
  static defaultProps = {
    url: null,
    header: null,
    teaserTitle: null,
    bodyText: null,
    backgroundColor: null,
    className: null,
    openInNewWindowOrTab: false,
  };

  render() {
    const {
      header,
      teaserTitle,
      url,
      bodyText,
      backgroundColor,
      className,
      openInNewWindowOrTab,
    } = this.props;

    const title = <Title type={TitleTypes.CONTACT}>{teaserTitle}</Title>;
    let bgStyle = {};
    if (backgroundColor !== null) {
      bgStyle = {
        backgroundColor,
        outline: `20px ${backgroundColor} solid`,
      };
    }

    const isRichText = typeof bodyText !== 'string';

    return (
      <div
        css={[useStyles, bgStyle]}
        className={`next-article${className !== null ? ` ${className}` : ''}`}
      >
        <Caption type={Caption.types.HEAVY}>{header}</Caption>
        {url !== null ? (
          <NwLink
            className="clickable-area"
            to={url}
            openInNewWindowOrTab={openInNewWindowOrTab}
          >
            {title}
          </NwLink>
        ) : (
          title
        )}
        {bodyText !== null && (
          <BodyText
            className="next-article-body-text"
            type={BodyText.types.STATIC16}
            as={isRichText ? 'div' : 'p'}
          >
            {!isRichText ? (
              bodyText
            ) : (
              <RichTextWrapper
                richText={bodyText}
                renderFunction={(richText, errorReporter) =>
                  RichTextRenderer.paragraphsWithFormattedTextLinksAndLists(
                    richText,
                    errorReporter,
                    openInNewWindowOrTab,
                  )
                }
              />
            )}
          </BodyText>
        )}
      </div>
    );
  }
}

export default NextArticle;
