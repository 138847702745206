// eslint-disable-next-line max-classes-per-file
export class ContentError extends Error {
  // eslint-disable-next-line class-methods-use-this
  isHardError(): boolean {
    throw Error('isHardError not implemented');
  }
}

export class ControlValueWarning extends ContentError {
  constructor(optionName) {
    const msg = `Missing a value for the option ${optionName}. Make sure a value is given if using fixed or custom control options.`;
    super(msg);
    this.name = 'ControlValueWarning';
  }

  // eslint-disable-next-line class-methods-use-this
  isHardError() {
    return false;
  }
}

export class ExcessiveControlWarning extends ContentError {
  constructor(unusedOptions) {
    const msg = `The option(s), ${unusedOptions.map(
      (name) => `${name}, `,
    )}was not used. Please check the configuration or remove it.\n`;
    super(msg);
    this.name = 'ControlValueWarning';
  }

  // eslint-disable-next-line class-methods-use-this
  isHardError() {
    return false;
  }
}

export class MismatchedInputControlWarning extends ContentError {
  constructor(optionName, message) {
    const msg = `Mismatched input for control option "${optionName}". ${message}`;
    super(msg);
    this.name = 'ControlValueWarning';
  }

  // eslint-disable-next-line class-methods-use-this
  isHardError() {
    return false;
  }
}

export class ContentQueryError extends ContentError {
  constructor(contentType, operation) {
    const msg = `An error occured during ${operation}. Missing at least one ${contentType} input.`;
    super(msg);
    this.name = 'ContentQueryError';
  }

  // eslint-disable-next-line class-methods-use-this
  isHardError() {
    return true;
  }
}

export class ExcessiveContentWarning extends ContentError {
  constructor(contentType?, amountExpected?, amountFound?, operation?) {
    let msg;
    if (contentType) {
      msg = `Warning: Found too many inputs of type ${contentType} during ${operation}. Got ${amountFound} but expected ${amountExpected}.`;
    } else {
      msg = 'Warning: An excessive amount of inputs given.';
    }
    super(msg);
    this.name = 'ExcessiveContentWarning';
  }

  // eslint-disable-next-line class-methods-use-this
  isHardError() {
    return false;
  }
}

export class RichTextInputWarning extends ContentError {
  constructor(policy, trigger) {
    const msg =
      `Part of the input to this rich text field was not rendered. The input to this rich text field follows the policy "${policy}".` +
      ` To see the allowed input for this field look up "${policy}" in Storybook under Documentation/Content Tools/RichText Editor/Output.` +
      ` The error was triggered by the use of ${trigger}.`;
    super(msg);
    this.name = 'RichTextInputWarning';
  }

  // eslint-disable-next-line class-methods-use-this
  isHardError() {
    return false;
  }
}
