import { BlockInstanceProps } from '../Block';
import WellGrid from '../../components/WellGrid';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'type',
    definition: controlOptionDefinitions.wellTypes,
  },
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class WellGridBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  static types = WellGrid.types;

  render() {
    const { blockContent } = this.props;
    const { type, backgroundColor } = this.controlOptions;

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM);
    });

    return (
      <WellGrid
        items={mediaItems}
        backgroundColor={backgroundColor}
        type={type}
      />
    );
  }
}

export default WellGridBlock;
