import { Component } from 'react';

import HorizontalLineAtom from './common/atoms/HorizontalLine';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

const styles = {
  paddingTop: '28px',
  paddingBottom: '28px',
};

type HorizontalLineProps = {
  backgroundColor?: string;
};

class HorizontalLine extends Component<HorizontalLineProps> {
  static defaultProps = {
    backgroundColor: null,
  };

  render() {
    const { backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="horizontal-line-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <HorizontalLineAtom />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default HorizontalLine;
