import { Component } from 'react';
import { css } from '@emotion/react';

import Caption from '../atoms/Caption';
import Title from '../atoms/Title';
import Image from '../atoms/Image';
import NwLink from '../NwLink';

import angledArrow from '../../../assets/angledArrow.png';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import TitleTypes from '../../../constants/title-types';
import ScreenSize from '../../../constants/screenSize';
import { SizesType } from '../../../types/ts/imgixQuery';

const useStyles = css({
  '& .caption': {
    textAlign: 'center',
    opacity: '0.5',
    marginBottom: '18px',
  },
  '& .next': {
    textAlign: 'center',
    marginBottom: '23px',
  },
  '& .clickable-area': {
    position: 'relative',
    display: 'flex',
    margin: 'auto',
    [Breakpoints.Mobile.mq]: {
      width: '343px',
      height: '220px',
    },
    [Breakpoints.TabletUp.mq]: {
      width: '560px',
      height: '360px',
    },
    '& .background-image': {
      width: 'inherit',
      height: 'inherit',
    },
    '& .arrow': {
      opacity: '0',
      transition: '0.3s',
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxWidth: '24%',
      transform: 'translate(-50%, -50%) rotate(20deg)',
      msTransform: 'translate(-50%, -50%) rotate(20deg)',
    },
    '&:hover': {
      '& .background-image': {
        opacity: '0.5',
        transition: '0.3s',
      },
      '& .arrow': {
        opacity: '1',
        transition: '0.3s',
        transform: 'translate(-50%, -50%) rotate(0deg)',
      },
    },
    '& .image': {
      objectFit: 'cover',
    },
  },
});

type NextPageProps = {
  image?: InternalPropTypes.Image;
  link?: InternalPropTypes.Link;
  bodyText?: InternalPropTypes.RichText;
  headerText?: InternalPropTypes.RichText;
};

class NextPage extends Component<NextPageProps> {
  static defaultProps = {
    image: null,
    link: '',
    bodyText: null,
    headerText: null,
  };

  render() {
    const { image, link, bodyText, headerText } = this.props;

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '343', h: '220' },
      [ScreenSize.Tablet]: { w: '560', h: '360' },
      [ScreenSize.Desktop]: { w: '560', h: '360' },
    };

    return (
      <div css={useStyles}>
        <Caption type={Caption.types.HERO}>{bodyText}</Caption>

        <Title type={TitleTypes.NEXT}>{headerText}</Title>

        <NwLink
          className="clickable-area"
          to={link.linkTo}
          openInNewWindowOrTab={link.openInNewWindowOrTab}
        >
          <Image className="background-image" image={image} sizes={sizes} />
          <img className="arrow" src={angledArrow} alt="arrow" />
        </NwLink>
      </div>
    );
  }
}

export default NextPage;
