import { BlockInstanceProps } from '../Block';
import LogoScroller from '../../components/LogoScroller';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class LogoScrollerBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let images;
    blockContent.doQuery((content) => {
      images = content.getAllOfType(ContentTypeEnum.IMAGE);
    });

    return (
      <LogoScroller
        header={block?.header?.main}
        images={images}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default LogoScrollerBlock;
