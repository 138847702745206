import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import FindUsMolecule from './common/molecules/FindUsMolecule';

import * as InternalPropTypes from '../constants/internal-types';

const style = {
  '&': {
    paddingTop: '100px',
    paddingBottom: '100px',
  },
};

type FindUsComponentProps = {
  locationText?: InternalPropTypes.RichText;
  addressText?: InternalPropTypes.RichText;
  image?: InternalPropTypes.Image;
  backgroundColor?: string;
};

class FindUsComponent extends Component<FindUsComponentProps> {
  static defaultProps = {
    locationText: null,
    addressText: null,
    image: null,
    backgroundColor: null,
  };

  render() {
    const { locationText, addressText, image, backgroundColor } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div className="find-us-component" css={[style, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <FindUsMolecule
              locationText={locationText}
              addressText={addressText}
              image={image}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default FindUsComponent;
