/* eslint-disable no-param-reassign */
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import NwLink from '../../components/common/NwLink';
import { ContentTypeEnum } from '../../constants/cms-constants';
// eslint-disable-next-line import/no-cycle
import Header from '../../components/common/atoms/Header';
// eslint-disable-next-line import/no-cycle
import BodyText from '../../components/common/atoms/BodyText';
import ContentfulPropMapper from '../ContentfulPropMapper';
import { EmbeddedBlock } from '../../containers/Block';

const optMuteEverything = (suppress) => ({
  renderMark: {
    [MARKS.BOLD]: suppress.trigger('bold'),
    [MARKS.CODE]: suppress.trigger('code'),
    [MARKS.ITALIC]: suppress.trigger('italic'),
    [MARKS.UNDERLINE]: suppress.trigger('underline'),
  },
  renderNode: {
    [BLOCKS.DOCUMENT]: suppress.trigger('document'),
    [BLOCKS.PARAGRAPH]: suppress.trigger('paragraph'),
    [BLOCKS.EMBEDDED_ASSET]: suppress.trigger('embedded asset'),
    [BLOCKS.EMBEDDED_ENTRY]: suppress.trigger('embedded entry'),
    [BLOCKS.HEADING_1]: suppress.trigger('heading 1'),
    [BLOCKS.HEADING_2]: suppress.trigger('heading 2'),
    [BLOCKS.HEADING_3]: suppress.trigger('heading 3'),
    [BLOCKS.HEADING_4]: suppress.trigger('heading 4'),
    [BLOCKS.HEADING_5]: suppress.trigger('heading 5'),
    [BLOCKS.HEADING_6]: suppress.trigger('heading 6'),
    [BLOCKS.QUOTE]: suppress.trigger('quote'),
    [BLOCKS.HR]: suppress.trigger('hr'),
    [BLOCKS.LIST_ITEM]: suppress.trigger('list item'),
    [BLOCKS.UL_LIST]: suppress.trigger('unordered list'),
    [BLOCKS.OL_LIST]: suppress.trigger('ordered list'),
    [INLINES.HYPERLINK]: suppress.trigger('hyperlink'),
    [INLINES.ASSET_HYPERLINK]: suppress.trigger('asset hyperlink'),
    [INLINES.ENTRY_HYPERLINK]: suppress.trigger('entry hyperlink'),
    [INLINES.EMBEDDED_ENTRY]: suppress.trigger('embedded entry'),
  },
});

function copyAndTap(suppresser, modifier) {
  const opts = optMuteEverything(suppresser);
  modifier(opts);
  return opts;
}

const renderTextReplaceNewlineWithBreak = (text) =>
  (text || '').split('\n').reduce((children, textSegment, index) => {
    if (index > 0) {
      // eslint-disable-next-line react/no-array-index-key
      children.push(<br key={index} />);
    }
    children.push(textSegment);
    return children;
  }, []);

const replaceNewlinesWithBreaks = {
  renderText: renderTextReplaceNewlineWithBreak,
};

function regularTextHelper(conf) {
  conf.renderMark[MARKS.BOLD] = (text) => text;
  conf.renderMark[MARKS.CODE] = (text) => text;
  conf.renderMark[MARKS.ITALIC] = (text) => text;
  conf.renderMark[MARKS.UNDERLINE] = (text) => text;
  conf.renderNode[BLOCKS.PARAGRAPH] = (node, children) => children;
  conf.renderNode[BLOCKS.DOCUMENT] = (node, children) => (
    <span>{children}</span>
  );
}

function regularTextWithLinebreakHelper(conf) {
  regularTextHelper(conf);
  conf.renderText = renderTextReplaceNewlineWithBreak;
}

function formattedTextHelper(conf) {
  conf.renderMark[MARKS.BOLD] = (text) => <b>{text}</b>;
  conf.renderMark[MARKS.CODE] = (text) => <code>{text}</code>;
  conf.renderMark[MARKS.ITALIC] = (text) => <i>{text}</i>;
  conf.renderMark[MARKS.UNDERLINE] = (text) => <u>{text}</u>;
  conf.renderNode[BLOCKS.PARAGRAPH] = (node, children) => children;
  conf.renderNode[BLOCKS.DOCUMENT] = (node, children) => (
    <span>{children}</span>
  );
  conf.renderText = renderTextReplaceNewlineWithBreak;
}

function regularListsHelper(conf) {
  // eslint-disable-next-line no-sequences, no-unused-expressions
  (conf.renderNode[BLOCKS.UL_LIST] = (node, children) => <ul>{children}</ul>),
    (conf.renderNode[BLOCKS.OL_LIST] = (node, children) => <ol>{children}</ol>),
    (conf.renderNode[BLOCKS.LIST_ITEM] = (node, children) => (
      <li>{children}</li>
    ));
}

function linksHelper(conf, openInNewWindowOrTab = false) {
  conf.renderNode[INLINES.HYPERLINK] = (node, children) => (
    <NwLink to={node.data.uri} openInNewWindowOrTab={openInNewWindowOrTab}>
      {children}
    </NwLink>
  );
  conf.renderNode[INLINES.ENTRY_HYPERLINK] = (node, children) => {
    const targetEntry = node.data.target;
    const targetIsPage =
      targetEntry.sys.contentType.sys.id === ContentTypeEnum.PAGE;
    return (
      <NwLink pageSlug={targetIsPage ? targetEntry.fields.pageSlug : null}>
        {children}
      </NwLink>
    );
  };
}

function linesHelper(conf) {
  // eslint-disable-next-line no-unused-vars
  conf.renderNode[BLOCKS.HR] = (node, children) => <hr />;
}

function headersHelper(conf) {
  conf.renderNode[BLOCKS.HEADING_1] = (node, children) => <h1>{children}</h1>;
  conf.renderNode[BLOCKS.HEADING_2] = (node, children) => <h2>{children}</h2>;
  conf.renderNode[BLOCKS.HEADING_3] = (node, children) => <h3>{children}</h3>;
  conf.renderNode[BLOCKS.HEADING_4] = (node, children) => <h4>{children}</h4>;
  conf.renderNode[BLOCKS.HEADING_5] = (node, children) => <h5>{children}</h5>;
  conf.renderNode[BLOCKS.HEADING_6] = (node, children) => <h6>{children}</h6>;
}

function embeddedEntryHelper(conf) {
  // eslint-disable-next-line consistent-return, no-unused-vars
  conf.renderNode[BLOCKS.EMBEDDED_ENTRY] = (node, children) => {
    const mapper = new ContentfulPropMapper();
    const block = mapper.mapRichTextToBlock(node);
    return <EmbeddedBlock block={block} />;
  };
}

function regularTextOnlyWithNoBreakLine(richText, errorReporter) {
  errorReporter.policyName = 'Regular text only with no breakLine';
  const policy = copyAndTap(errorReporter, (conf) => {
    regularTextHelper(conf);
  });
  return documentToReactComponents(richText, policy);
}

function regularTextOnly(richText, errorReporter) {
  errorReporter.policyName = 'Regular text only';
  const policy = copyAndTap(errorReporter, (conf) => {
    regularTextWithLinebreakHelper(conf);
  });
  return documentToReactComponents(richText, policy);
}

function formattedTextOnly(richText, errorReporter) {
  errorReporter.policyName = 'Formatted text only';
  const policy = copyAndTap(errorReporter, (conf) => {
    formattedTextHelper(conf);
  });
  return documentToReactComponents(richText, policy);
}

function formattedTextAndEmbeddedEntries(richText, errorReporter) {
  errorReporter.policyName = 'Formatted text and embedded entries';
  const policy = copyAndTap(errorReporter, (conf) => {
    formattedTextHelper(conf);
    embeddedEntryHelper(conf);
  });
  return documentToReactComponents(richText, policy);
}

function regularTextWithLinks(
  richText,
  errorReporter,
  openInNewWindowOrTab = false,
) {
  errorReporter.policyName = 'Regular text with links';
  const policy = copyAndTap(errorReporter, (conf) => {
    regularTextHelper(conf);
    linksHelper(conf, openInNewWindowOrTab);
  });
  return documentToReactComponents(richText, policy);
}

function textHeadersLinksAndListsInDiv(
  richText,
  errorReporter,
  openInNewWindowOrTab = false,
) {
  errorReporter.policyName = 'Text headers links and lists in div';
  const policy = copyAndTap(errorReporter, (conf) => {
    regularListsHelper(conf);
    linksHelper(conf, openInNewWindowOrTab);
    conf.renderNode[BLOCKS.PARAGRAPH] = (node, children) => <p>{children}</p>;
    conf.renderNode[BLOCKS.DOCUMENT] = (node, children) => (
      <div>{children}</div>
    );
    conf.renderNode[BLOCKS.HEADING_2] = (node, children) => <h2>{children}</h2>;
    conf.renderNode[BLOCKS.HEADING_3] = (node, children) => <h3>{children}</h3>;
    conf.renderText = renderTextReplaceNewlineWithBreak;
  });
  return documentToReactComponents(richText, policy);
}

function paragraphsWithFormattedTextLinksAndLists(
  richText,
  errorReporter,
  openInNewWindowOrTab = false,
) {
  errorReporter.policyName = 'Paragraphs with formatted text links and lists';
  const policy = copyAndTap(errorReporter, (conf) => {
    formattedTextHelper(conf);
    linksHelper(conf, openInNewWindowOrTab);
    regularListsHelper(conf);
    embeddedEntryHelper(conf);
    conf.renderNode[BLOCKS.PARAGRAPH] = (node, children) => <p>{children}</p>;
    conf.renderNode[BLOCKS.DOCUMENT] = (node, children) => (
      <div>{children}</div>
    );
  });
  return documentToReactComponents(richText, policy);
}

function headersItalicBoldLinksAndLists(
  richText,
  errorReporter,
  openInNewWindowOrTab = false,
) {
  errorReporter.policyName = 'Headers, italic, bold, links and lists';
  const policy = copyAndTap(errorReporter, (conf) => {
    linksHelper(conf, openInNewWindowOrTab);
    regularListsHelper(conf);
    formattedTextHelper(conf);
    embeddedEntryHelper(conf);
    conf.renderMark[MARKS.CODE] = errorReporter.trigger('code'); // eslint-disable-line no-unused-vars
    conf.renderMark[MARKS.UNDERLINE] = errorReporter.trigger('underline'); // eslint-disable-line no-unused-vars
    conf.renderNode[BLOCKS.PARAGRAPH] = (node, children) => (
      <BodyText type={BodyText.types.STATIC16ARTICLE}>{children}</BodyText>
    );
    conf.renderNode[BLOCKS.DOCUMENT] = (node, children) => (
      <div>{children}</div>
    );
    conf.renderNode[BLOCKS.HEADING_2] = (node, children) => (
      <Header type={Header.types.TEXTBOXH2}>{children}</Header>
    );
    conf.renderNode[BLOCKS.HEADING_3] = (node, children) => (
      <Header type={Header.types.TEXTBOXH3}>{children}</Header>
    );
    conf.renderNode[BLOCKS.HEADING_4] = (node, children) => (
      <Header type={Header.types.TEXTBOXH4}>{children}</Header>
    );
    conf.renderNode[BLOCKS.HEADING_5] = (node, children) => (
      <Header type={Header.types.TEXTBOXH5}>{children}</Header>
    );
    conf.renderNode[BLOCKS.HEADING_6] = (node, children) => (
      <Header type={Header.types.TEXTBOXH6}>{children}</Header>
    );
  });
  return documentToReactComponents(richText, policy);
}

function allowEverything(
  richText,
  errorReporter,
  openInNewWindowOrTab = false,
) {
  errorReporter.policyName = 'Headers, italic, bold, links and lists';
  const policy = copyAndTap(errorReporter, (conf) => {
    // all helpers should be included here
    regularTextHelper(conf);
    regularTextWithLinebreakHelper(conf);
    formattedTextHelper(conf);
    regularListsHelper(conf);
    linksHelper(conf, openInNewWindowOrTab);
    linesHelper(conf);
    headersHelper(conf);
    embeddedEntryHelper(conf);
    conf.renderNode[BLOCKS.PARAGRAPH] = (node, children) => <p>{children}</p>;
    conf.renderNode[BLOCKS.DOCUMENT] = (node, children) => (
      <div>{children}</div>
    );
  });
  return documentToReactComponents(richText, policy);
}

const renderer = {
  regularTextOnlyWithNoBreakLine,
  regularTextOnly,
  formattedTextOnly,
  formattedTextAndEmbeddedEntries,
  regularTextWithLinks,
  replaceNewlinesWithBreaks,
  allowEverything,
  textHeadersLinksAndListsInDiv,
  paragraphsWithFormattedTextLinksAndLists,
  headersItalicBoldLinksAndLists,
};

export default renderer;
