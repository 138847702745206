// eslint-disable-next-line import/extensions, import/no-unresolved
import { LANGUAGES } from '../constants/language';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { PagePresentationEnum } from '../constants/cms-constants';

export const isoToUrl = (isoLang) =>
  LANGUAGES.find((l) => l.iso === isoLang).urlLanguage;

export const changeHashToQuery = (path) => (path || '').replace('#', '?');

const getUrlLanguageFromPageLanguage = (languageName) =>
  `/${LANGUAGES.find((l) => l.name === languageName).urlLanguage}`;

const getPageUrlFromLanguageAndSlug = (languageName, slug) =>
  getUrlLanguageFromPageLanguage(languageName) + slug;

export const getPageUrlFromPageReference = (pageReference) =>
  pageReference.presentation === PagePresentationEnum.MODAL
    ? changeHashToQuery(pageReference.modalSlug)
    : getPageUrlFromLanguageAndSlug(
        pageReference.language,
        pageReference.pageSlug,
      );

export const getPageUrlFromPage = (page) =>
  (page.slug && page.isModalPage
    ? changeHashToQuery(page.slug.modal)
    : getPageUrlFromLanguageAndSlug(page.language, page.slug.page)
  ).toLowerCase();

export const getModalPageUrlFromPage = (page) =>
  `${getUrlLanguageFromPageLanguage(page.language)}/${changeHashToQuery(
    page.slug.modal,
  )}`;

export const addLanguagePrefixToSlug = (iso, slug) => isoToUrl(iso) + slug;

export const getBasepathWithLanguageFromUrl = (pageUrl) => {
  const urlRegex = /((?:https|http):\/\/.+(?:en|dk)\/)/g;
  return pageUrl?.match(urlRegex) ? pageUrl.match(urlRegex)[0] : undefined;
};

export const getBasepathFromUrl = (pageUrl) => {
  const urlRegex = /https?:\/\/.*?(?=\/|$)/g;
  return pageUrl?.match(urlRegex) ? pageUrl.match(urlRegex)[0] : undefined;
};

export const stripLanguageFromPageUrl = (url, isoLanguage) =>
  typeof url === 'string'
    ? url.replace(new RegExp(`^/${isoToUrl(isoLanguage)}`), '')
    : url;

export const getPageUrlWithoutLanguageFromPage = (page, isoLanguage) =>
  stripLanguageFromPageUrl(getPageUrlFromPage(page), isoLanguage);

export const getLanguageIsoFromUrl = (url) => {
  const matches = `${url}/`.match(/^\/([a-zA-Z]{2})\/.*/);
  if (matches && matches.length > 1) {
    const lang = LANGUAGES.find((l) => l.urlLanguage === matches[1]);
    return lang ? lang.iso : null;
  }
  return null;
};

export const urlContainsUppercase = (url) => /[A-Z]/.test(url);
