import { Component } from 'react';

import FactBox from './common/molecules/FactBox';
import ContentOuterBound from './common/atoms/ContentOuterBound';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';

const styles = {
  '&.fact-box-component ': {
    paddingTop: '32px',
    paddingBottom: '32px',
    paddingLeft: '22px',
    paddingRight: '22px',

    [Breakpoints.Mobile.mq]: {
      marginBottom: '60px',
      marginTop: '60px',
    },
    [Breakpoints.Tablet.mq]: {
      marginBottom: '64px',
      marginTop: '64px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '56px',
      marginTop: '56px',
    },
  },
};

type FactBoxComponentProps = {
  titleText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  type?: typeof FactBox.types[keyof typeof FactBox.types];
  backgroundColor?: string;
};

class FactBoxComponent extends Component<FactBoxComponentProps> {
  static defaultProps = {
    titleText: null,
    bodyText: null,
    type: FactBox.types.CONTACT,
    backgroundColor: null,
  };

  render() {
    const { titleText, bodyText, type, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div css={[styles, addStyle]} className={`fact-box-component ${type}`}>
        <ContentOuterBound>
          {(titleText || bodyText) && (
            <FactBox type={type} titleText={titleText} bodyText={bodyText} />
          )}
        </ContentOuterBound>
      </div>
    );
  }
}

export default FactBoxComponent;
