import React, { Component } from 'react';
import { css } from '@emotion/react';
import HstackSpacing, { HstackSpacingEnum } from './common/atoms/HstackSpacing';
import FooterCard from './common/molecules/FooterCard';
import ContentSpacer from './common/atoms/ContentSpacer';
import * as Breakpoints from '../constants/breakpoints';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import GridEvenSplit from './common/atoms/GridEvenSplit';
import Title from './common/atoms/Title';
import BodyText from './common/atoms/BodyText';
import CallToAction from './common/molecules/CallToAction';
import MediaQuery from './common/atoms/MediaQuery';
import Image from './common/atoms/Image';
import Header from './common/atoms/Header';
import FooterSnippet from './common/atoms/FooterSnippet';
import RichTextRenderer from '../utils/content/RichTextRenderer';
import { colorEnum } from '../constants/colors';
import NwLink from './common/NwLink';
import { changeHashToQuery } from '../utils/url';
import RichTextWrapper from './common/RichTextWrapper';
import * as InternalPropTypes from '../constants/internal-types';
import TitleTypes from '../constants/title-types';
import ScreenSize from '../constants/screenSize';
import { SizesType } from '../types/ts/imgixQuery';

const styles = css({
  backgroundColor: colorEnum.black,
  color: colorEnum.white,
  [Breakpoints.Mobile.mq]: {
    paddingTop: '75px',
    paddingBottom: '75px',
    '& .big-navigation-links .cell + .cell': {
      marginTop: '25px',
    },
    '& .big-navigation-links + .big-navigation-links': {
      marginTop: '25px',
    },
    '& .big-navigation-links + .bottom-text': {
      marginTop: '40px',
    },
    '& .bottom-text .cell + .cell': {
      marginTop: '35px',
    },
    '& .bottom-text + .bottom-text': {
      marginTop: '35px',
    },
  },
  [Breakpoints.Tablet.mq]: {
    '& .big-navigation-links + .big-navigation-links': {
      marginTop: '40px',
    },
    '& .big-navigation-links + .bottom-text': {
      marginTop: '80px',
    },
    '& .bottom-text + .bottom-text': {
      marginTop: '40px',
    },
    '& .bottom-text .row ': {
      '& .cell:first-of-type': {
        paddingRight: '1em',
      },
    },
  },
  [Breakpoints.TabletUp.mq]: {
    paddingTop: '90px',
    paddingBottom: '100px',
  },
  '& .footer-contact-header-text': {
    color: colorEnum.white,
  },
  '& .image': {
    width: '125px',
    height: 'auto',
  },
  '& .footer-contact-grid': {
    '& .right-cell': {
      [Breakpoints.TabletUp.mq]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      [Breakpoints.Mobile.mq]: {
        marginTop: '10px',
      },
    },
  },
  '& .footer-logo-grid': {
    '& .right-cell': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  '& .footer-separator': {
    [Breakpoints.Mobile.mq]: {
      marginTop: '70px',
      marginBottom: '70px',
    },
    [Breakpoints.TabletUp.mq]: {
      marginTop: '85px',
      marginBottom: '95px',
    },
    borderTop: '1px solid #253139',
  },
  '& .small-navigation-links': {
    marginBottom: 0,
    marginTop: '2px',
    '& li': {
      display: 'inline',
    },
    '& li + li': {
      marginLeft: '20px',
    },
    '& .body-text': {
      color: colorEnum.white,
      opacity: 0.5,
    },
  },
  '& .lower-footer': {
    marginTop: '60px',
    '& .header-text, .body-text, a': {
      color: colorEnum.white,
    },
  },
});

type PageFooterProps = {
  footer?: InternalPropTypes.Footer;
  companyNameWhiteImage?: InternalPropTypes.Image;
  spacing?:
    | typeof HstackSpacingEnum.SPACEBETWEEN
    | typeof HstackSpacingEnum.CENTER;
};
class PageFooter extends Component<PageFooterProps> {
  static defaultProps = {
    footer: {
      contact: { header: null, link: null },
      finePrint: [],
      navigationLinks: { small: [], large: [] },
      soMe: [],
    },
    companyNameWhiteImage: null,
    spacing: HstackSpacing.SPACEBETWEEN,
  };

  render() {
    const { footer, companyNameWhiteImage, spacing } = this.props;
    const { contact, finePrint, navigationLinks, soMe } = footer;

    const footerCards = [0, 1, 2, 3].map((idx) => ({
      finePrint: !(idx === 3) ? finePrint?.[idx] : null,
      headerLink: navigationLinks?.large?.[idx],
      soMe: idx === 3 ? soMe : null,
    }));

    const url = (idx) => changeHashToQuery(navigationLinks.large[idx].linkTo);
    const bigNavRenderFuncs = [0, 1, 2, 3].map(
      (idx) =>
        navigationLinks &&
        navigationLinks.large &&
        navigationLinks.large[idx] && (
          <NwLink
            to={url(idx)}
            openInNewWindowOrTab={
              navigationLinks.large[idx].openInNewWindowOrTab
            }
          >
            <Header type={Header.types.FOOTER}>
              {navigationLinks.large[idx].text}
            </Header>
          </NwLink>
        ),
    );

    const smallNavigationLinks = navigationLinks && navigationLinks.small;

    const snippetRenderFuncs = [0, 1, 2, 3].map(
      (idx) =>
        finePrint &&
        finePrint[idx] && <FooterSnippet finePrint={finePrint[idx]} />,
    );

    const sizes: SizesType = {
      [ScreenSize.Mobile]: { w: '125' },
      [ScreenSize.Tablet]: { w: '125' },
      [ScreenSize.Desktop]: { w: '125' },
    };

    return (
      <footer css={styles}>
        <ContentOuterBound>
          <ContentSpacer>
            <>
              <GridEvenSplit
                className="footer-contact-grid"
                onRenderCells={[
                  () => (
                    <Title
                      className="footer-contact-header-text"
                      type={TitleTypes.SUMMARY}
                    >
                      {contact && (
                        <RichTextWrapper
                          richText={contact.header}
                          renderFunction={RichTextRenderer.regularTextOnly}
                        />
                      )}
                    </Title>
                  ),
                  () =>
                    contact &&
                    contact.link && (
                      <CallToAction
                        link={contact.link}
                        type={CallToAction.types.FOOTER}
                      />
                    ),
                ]}
                snapAtBreakpoint={Breakpoints.Mobile}
              />
              <hr className="footer-separator" />
              <GridEvenSplit
                className="footer-logo-grid"
                onRenderCells={[
                  () => (
                    <NwLink aria-label="link to frontpage" to="/">
                      <Image image={companyNameWhiteImage} sizes={sizes} />
                    </NwLink>
                  ),
                  () => (
                    <ul className="small-navigation-links">
                      {smallNavigationLinks.map((link, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={`smallnavlink${idx}`}>
                          <NwLink
                            key={link.name}
                            to={link.linkTo}
                            openInNewWindowOrTab={link.openInNewWindowOrTab}
                          >
                            <BodyText as="span" type={BodyText.types.STATIC14}>
                              <RichTextWrapper
                                richText={link.text}
                                renderFunction={
                                  RichTextRenderer.regularTextOnly
                                }
                              />
                            </BodyText>
                          </NwLink>
                        </li>
                      ))}
                    </ul>
                  ),
                ]}
              />
              <div className="lower-footer">
                <MediaQuery
                  visible={[Breakpoints.Desktop, Breakpoints.DesktopBounded]}
                >
                  <HstackSpacing spacing={spacing}>
                    {footerCards.map((card, idx) => (
                      <FooterCard
                        // eslint-disable-next-line react/no-array-index-key
                        key={`footercard${idx}`}
                        headerLink={card.headerLink}
                        finePrint={card.finePrint}
                        soMeLinks={card.soMe}
                      />
                    ))}
                  </HstackSpacing>
                </MediaQuery>
                <MediaQuery visible={[Breakpoints.Tablet, Breakpoints.Mobile]}>
                  {/* big nav link grids */}
                  <GridEvenSplit
                    snapAtBreakpoint={Breakpoints.Mobile}
                    className="big-navigation-links"
                    onRenderCells={[
                      () => bigNavRenderFuncs[0],
                      () => bigNavRenderFuncs[1],
                    ]}
                  />
                  <GridEvenSplit
                    snapAtBreakpoint={Breakpoints.Mobile}
                    className="big-navigation-links"
                    onRenderCells={[
                      () => bigNavRenderFuncs[2],
                      () => bigNavRenderFuncs[3],
                    ]}
                  />
                  {/* bottom text grids */}
                  <GridEvenSplit
                    snapAtBreakpoint={Breakpoints.Mobile}
                    className="bottom-text"
                    onRenderCells={[
                      () => snippetRenderFuncs[0],
                      () => snippetRenderFuncs[1],
                    ]}
                  />
                  <GridEvenSplit
                    snapAtBreakpoint={Breakpoints.Mobile}
                    className="bottom-text"
                    onRenderCells={[
                      () => snippetRenderFuncs[2],
                      () => snippetRenderFuncs[3],
                    ]}
                  />
                </MediaQuery>
              </div>
            </>
          </ContentSpacer>
        </ContentOuterBound>
      </footer>
    );
  }
}

export default PageFooter;
