import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import BodyText from './common/atoms/BodyText';
import CaseHero from './common/molecules/CaseHero';
import NwLink from './common/NwLink';

import * as Breakpoints from '../constants/breakpoints';
import * as InternalPropTypes from '../constants/internal-types';

const styles = {
  [Breakpoints.Mobile.mq]: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '58px',
    paddingBottom: '58px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
  '& .title': {
    marginBottom: '14px',
  },
  ':hover': {
    '& a': {
      textDecoration: 'none',
    },
  },
};

type NextCaseProps = {
  mediaItem?: InternalPropTypes.MediaItem;
  title?: InternalPropTypes.RichText;
  caseText?: InternalPropTypes.RichText;
  caseSlug?: string;
  backgroundColor?: string;
  openInNewWindowOrTab?: boolean;
};

class NextCase extends Component<NextCaseProps> {
  static defaultProps = {
    mediaItem: null,
    caseText: null,
    title: null,
    caseSlug: null,
    backgroundColor: null,
    openInNewWindowOrTab: false,
  };

  render() {
    const {
      title,
      caseSlug,
      caseText,
      mediaItem,
      backgroundColor,
      openInNewWindowOrTab,
    } = this.props;
    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="next-case-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <BodyText className="title" type={BodyText.types.BOLD14}>
              {title}
            </BodyText>
            <NwLink to={caseSlug} openInNewWindowOrTab={openInNewWindowOrTab}>
              <CaseHero
                image={mediaItem.image}
                caseText={caseText}
                headerText={mediaItem.header}
                lightHeaderText={mediaItem.lightHeader}
                bodyText={mediaItem.bodyText}
              />
            </NwLink>
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default NextCase;
