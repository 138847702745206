/* eslint-disable react/prop-types */
import { compose } from 'recompose';
import './language/i18n';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import type { UnregisterCallback, Location, Action } from 'history';
import AppConfigContext from './contexts/AppConfigContext';
import { withAppErrors } from './utils/hocs/withErrors';
import CookieService from './client-server-utils/CookieService';
import { ConfigBase } from './client-server-utils/StaticConfig';
import AppSettings from './client-server-utils/AppSettings';
import * as urlutil from './utils/url';
import LoadingListener, {
  LoadingListenerProps,
  LoadingListenerState,
} from './containers/LoadingListener';
import withAppConfig, { WithAppConfigProps } from './utils/hocs/withAppConfig';
import { LoadResult } from './client-server-utils/loading/LoadingManager';
import { isPageCacheKey } from './containers/Page';
import Root, { isRootCacheKey } from './containers/Root';
import { Page as PageType, Root as RootType } from './constants/internal-types';
import withLoadingContext from './utils/hocs/withLoadingContext';
import Consent from './utils/Consent';
import { createAppConfig } from './client-server-utils/appConfig';
import withReporterService, {
  withReporterServiceContextProps,
} from './utils/hocs/withReporterService';

// Typescript decorators are incorrectly flagged as references to global
// variables, some of which are restricted. Hence the ignores below.

type AppProps = {
  cookieService: CookieService;
  staticConfig: ConfigBase;
};

type AppInnerProps = RouteComponentProps &
  LoadingListenerProps &
  WithAppConfigProps &
  withReporterServiceContextProps &
  AppProps & {
    i18n: i18n;
  };

type AppState = LoadingListenerState & {
  appSettings: AppSettings;
  historyUnregistration?: UnregisterCallback | null;
  root: RootType;
  currentPageAlternates: PageType;
  consent: {
    resolve: (consent: Consent) => void | null;
    promise: Promise<Consent>;
  };
};

class App extends LoadingListener<AppInnerProps, AppState> {
  constructor(props: AppInnerProps) {
    super(props);

    const consentString = props.cookieService.getConsentCookie();
    const { consent, consentPromise, resolveConsentFunction } =
      Consent.initializeConsent(consentString);

    this.state = {
      ...this.state,
      consent: { resolve: resolveConsentFunction, promise: consentPromise },
      appSettings: new AppSettings({
        staticConfig: this.props.staticConfig,
        cookieService: props.cookieService,
        isoLanguage: props.i18n.language,
        consent,
      }),
    };

    this._onChangeLanguage = this._onChangeLanguage.bind(this);
    this._onCookieConsentGiven = this._onCookieConsentGiven.bind(this);
    this._onChangeContentfulEnvironment =
      this._onChangeContentfulEnvironment.bind(this);
    this._onTogglePreview = this._onTogglePreview.bind(this);
    this._historyEvent = this._historyEvent.bind(this);
  }

  onLoadResult(loadResult: LoadResult): void {
    if (isRootCacheKey(loadResult.key)) {
      this.setState({ root: loadResult.response });
    } else if (isPageCacheKey(loadResult.key)) {
      const page = loadResult.response as PageType;
      this.setState({ currentPageAlternates: page });
    }
  }

  // eslint-disable-next-line react/sort-comp
  _historyEvent(location: Location, action: Action) {
    if (action) {
      const urlLanguage = urlutil.getLanguageIsoFromUrl(location.pathname);
      if (
        urlLanguage &&
        urlLanguage !== this.state.appSettings.language.currentIso
      ) {
        // language was changed during navigation
        this._onChangeLanguage(urlLanguage);
      }
    }
  }

  componentDidMount(...args) {
    const { reporterService } = this.props;
    super.componentDidMount.apply(this, args);
    this.setState({
      historyUnregistration: this.props.history.listen(this._historyEvent),
    });
    this.state.consent.promise.then((consent) =>
      reporterService.activate(consent),
    );
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    this.state.historyUnregistration && this.state.historyUnregistration();
    this.setState({ historyUnregistration: null });
  }

  componentDidUpdate(prevProps) {
    const { reporterService } = this.props;
    const reporterServiceDidChange =
      prevProps.reporterService !== reporterService;
    if (reporterService && reporterServiceDidChange) {
      this.state.consent.promise.then((consent) =>
        reporterService.activate(consent),
      );
    }
  }

  _onChangeContentfulEnvironment(environment) {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    cookieService.setEnvironment(environment);
    this.setState({
      appSettings: new AppSettings({
        staticConfig,
        cookieService,
        consent: appSettings.consent,
        isoLanguage: appSettings.language.currentIso,
      }),
    });
  }

  _onTogglePreview() {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    cookieService.setPreview(!appSettings.contentful.isPreview);
    this.setState({
      appSettings: new AppSettings({
        staticConfig,
        cookieService,
        consent: appSettings.consent,
        isoLanguage: appSettings.language.currentIso,
      }),
    });
  }

  _onCookieConsentGiven(consent) {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    cookieService.setConsentCookie(consent.getCookieString());
    this._updateLanguage(appSettings.language.currentIso);
    if (this.state.consent.resolve !== null) {
      this.state.consent.resolve(consent);
      this.setState({
        consent: { promise: this.state.consent.promise, resolve: null },
        appSettings: new AppSettings({
          staticConfig,
          cookieService,
          consent,
          isoLanguage: appSettings.language.currentIso,
        }),
      });
    }
  }

  _updateLanguage(lng) {
    const { cookieService } = this.props;
    const { consent } = this.state;
    this.props.i18n.changeLanguage(lng);
    consent.promise.then(() => {
      cookieService.setLanguage(lng);
    });
    if (typeof window !== 'undefined') {
      document.documentElement.lang = lng;
    }
  }

  _onChangeLanguage(lng) {
    const { cookieService, staticConfig } = this.props;
    const { appSettings } = this.state;
    this._updateLanguage(lng);
    this.setState({
      appSettings: new AppSettings({
        staticConfig,
        consent: appSettings.consent,
        cookieService,
        isoLanguage: lng,
      }),
    });
  }

  render() {
    const { staticConfig, reporterService, cookieService } = this.props;

    const eventHandlers = {
      onChangeLanguage: this._onChangeLanguage,
      onChangeContentfulEnvironment: this._onChangeContentfulEnvironment,
      onTogglePreview: this._onTogglePreview,
      onCookieConsentGiven: this._onCookieConsentGiven,
    };
    const services = {
      cookieService,
      reporterService,
    };
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const appConfig = createAppConfig(
      staticConfig,
      this.state.appSettings,
      this.state.currentPageAlternates,
      services,
      eventHandlers,
    );

    return (
      <AppConfigContext.Provider value={appConfig}>
        <Root />
      </AppConfigContext.Provider>
    );
  }
}

export default compose<AppInnerProps, AppProps>(
  withAppErrors,
  withTranslation(),
  withRouter,
  withReporterService,
  withAppConfig,
  withLoadingContext,
)(App as any);
