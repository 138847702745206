import { BlockInstanceProps } from '../Block';
import LetsTalkComponent from '../../components/LetsTalk';
import { ContentTypeEnum } from '../../constants/cms-constants';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class LetsTalkBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent, connectors } = this.props;
    const { self } = connectors;
    const { backgroundColor } = this.controlOptions;

    let mediaItems;
    let images;
    let persons;
    let snippets;
    blockContent.doQuery((content) => {
      mediaItems = content.getFixedAmountOfType(ContentTypeEnum.MEDIAITEM, 2);
      images = content.getFixedAmountOfType(ContentTypeEnum.IMAGE, 3);
      persons = content.getFixedAmountOfType(ContentTypeEnum.PERSON, 2, false);
      snippets = content.getAllOfType(ContentTypeEnum.SNIPPET);
    });

    const pointerHeaders = snippets.filter((s) =>
      s.name.toLowerCase().includes('pointer'),
    );
    const messageFormDescription = snippets.find((s) =>
      s.name.toLowerCase().includes('message form description'),
    );
    const bookingFormDescription = snippets.find((s) =>
      s.name.toLowerCase().includes('booking form description'),
    );
    const sendingMessage = snippets.find((s) =>
      s.name.toLowerCase().includes('sending submit'),
    );
    const submitMessage = snippets.find((s) =>
      s.name.toLowerCase().includes('successful submit'),
    );
    const failedSubmitMessage = snippets.find((s) =>
      s.name.toLowerCase().includes('failed submit'),
    );
    const submitButtonText = snippets.find((s) =>
      s.name.toLowerCase().includes('submit button text'),
    );
    const namePlaceholder = snippets.find((s) =>
      s.name.toLowerCase().includes('name placeholder'),
    );
    const emailPlaceholder = snippets.find((s) =>
      s.name.toLowerCase().includes('email placeholder'),
    );
    const messagePlaceholder = snippets.find((s) =>
      s.name.toLowerCase().includes('message placeholder'),
    );
    const datePlaceholder = snippets.find((s) =>
      s.name.toLowerCase().includes('date placeholder'),
    );
    const participantsPlaceholder = snippets.find((s) =>
      s.name.toLowerCase().includes('participants placeholder'),
    );

    return (
      <LetsTalkComponent
        headerText={block?.header?.main}
        bodyText={block?.bodyText}
        images={images}
        profiles={persons}
        mediaItems={mediaItems}
        backgroundColor={backgroundColor}
        pointerHeaders={pointerHeaders}
        bookingFormDescription={bookingFormDescription}
        messageFormDescription={messageFormDescription}
        submitMessage={submitMessage}
        sendingMessage={sendingMessage}
        failedSubmitMessage={failedSubmitMessage}
        submitButtonText={submitButtonText}
        namePlaceholder={namePlaceholder}
        emailPlaceholder={emailPlaceholder}
        messagePlaceholder={messagePlaceholder}
        datePlaceholder={datePlaceholder}
        participantsPlaceholder={participantsPlaceholder}
        submitContactForm={self.submitContactForm}
        submitBookingForm={self.submitBookingForm}
      />
    );
  }
}

export default LetsTalkBlock;
