import { BlockInstanceProps } from '../Block';
import { ContentTypeEnum } from '../../constants/cms-constants';
import Header2TextGroup from '../../components/Header2TextGroup';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
])
class Header2TextGroupBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { backgroundColor } = this.controlOptions;

    let snippets;
    blockContent.doQuery((content) => {
      snippets = content.getFixedAmountOfType(ContentTypeEnum.SNIPPET, 2);
    });

    return (
      <Header2TextGroup
        headerText={block?.header?.main}
        leftSnippet={snippets[0]}
        rightSnippet={snippets[1]}
        backgroundColor={backgroundColor}
      />
    );
  }
}

export default Header2TextGroupBlock;
