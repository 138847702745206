import { Component } from 'react';
import { css } from '@emotion/react';

import BodyText from '../atoms/BodyText';
import Grid3CellEvenWithGutter from './Grid3CellEvenWithGutter';
import GridEvenSplit from '../atoms/GridEvenSplit';
import CallToAction from './CallToAction';

import * as InternalPropTypes from '../../../constants/internal-types';
import * as Breakpoints from '../../../constants/breakpoints';
import {
  List3GroupsTypeEnum,
  TypeList3GroupsTypes,
} from '../../../constants/cms-constants';

const styles = css({
  position: 'relative',
  '& .header': {
    [Breakpoints.Mobile.mq]: {
      marginBottom: '40px',
    },
    [Breakpoints.Tablet.mq]: {
      marginBottom: '48px',
    },
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '72px',
    },
  },

  '& .list-3-group-molecule': {
    '& .bodytext-local': {
      [Breakpoints.Mobile.mq]: {
        marginBottom: '32px',
      },
    },
  },
  '& .list-3-group-molecule-header': {
    '& .bodytext-local': {
      [Breakpoints.Mobile.mq]: {
        marginBottom: '46px',
      },
      [Breakpoints.Tablet.mq]: {
        marginBottom: '56px',
      },
    },
  },
  '& .list-3-group-molecule-link': {
    [Breakpoints.Mobile.mq]: {
      '& .left-cell': {
        marginBottom: '8px',
      },
      '& .center-cell': {
        marginBottom: '16px',
      },
    },
    '& .cta': {
      [Breakpoints.Mobile.mq]: {
        marginTop: '16px',
      },
      [Breakpoints.Tablet.mq]: {
        marginTop: '23px',
      },
      [Breakpoints.DesktopUp.mq]: {
        marginTop: '18px',
      },
    },
    '& .hiding': {
      [Breakpoints.Mobile.mq]: {
        display: 'none',
      },
    },
    '& .text-header': {
      marginBottom: '19px',
      minWidth: '280px',
    },
  },
  '& .text-header': {
    marginBottom: '19px',
    minWidth: '280px',
  },
});

type List3GroupsProps = {
  type?: TypeList3GroupsTypes;
  link?: InternalPropTypes.Link;
  headerText?: InternalPropTypes.RichText;
  leftHeader?: InternalPropTypes.RichText;
  middleHeader?: InternalPropTypes.RichText;
  rightHeader?: InternalPropTypes.RichText;
  leftBodytext?: InternalPropTypes.RichText;
  middleBodytext?: InternalPropTypes.RichText;
  rightBodyText?: InternalPropTypes.RichText;
  breakPoint?: InternalPropTypes.Breakpoint;
};

class List3Groups extends Component<List3GroupsProps> {
  static types = List3GroupsTypeEnum;

  static defaultProps = {
    type: null,
    link: null,
    headerText: '',
    leftHeader: null,
    middleHeader: null,
    rightHeader: null,
    leftBodytext: null,
    middleBodytext: null,
    rightBodyText: null,
    breakPoint: null,
  };

  render() {
    const {
      breakPoint,
      type,
      link,
      headerText,
      leftHeader,
      middleHeader,
      rightHeader,
      leftBodytext,
      middleBodytext,
      rightBodyText,
    } = this.props;

    const moleculeType =
      type === List3Groups.types.LINK || type === List3Groups.types.LINKLAST
        ? List3Groups.types.LINK
        : List3Groups.types.HEADERS;

    const breakPoint2 =
      !breakPoint && type === List3Groups.types.HEADERS
        ? Breakpoints.Tablet
        : Breakpoints.Mobile;
    const bodyTextType = BodyText.types.STATIC16;
    const Grid =
      moleculeType === List3Groups.types.LINK
        ? GridEvenSplit
        : Grid3CellEvenWithGutter;
    const headerTextType =
      type === List3Groups.types.HEADERS
        ? BodyText.types.PROFILEQUOTE
        : BodyText.types.LISTGROUPBOLD;

    let className = 'list-3-group-molecule';
    if (moleculeType === List3Groups.types.HEADERS) {
      className += `-${List3Groups.types.HEADERS}`;
    } else if (moleculeType === List3Groups.types.LINK) {
      className += `-${List3Groups.types.LINK}`;
    }

    return (
      <div css={styles}>
        <div className={className}>
          <BodyText className="header" type={BodyText.types.LISTGROUPBOLD}>
            {headerText}
          </BodyText>

          <Grid
            snapAtBreakpoint={breakPoint2}
            onRenderCells={[
              () => (
                <div className={`left${headerText ? '-1' : '-2'}`}>
                  <BodyText
                    className={`text-header${
                      moleculeType === List3Groups.types.LINK ? ' hiding' : ''
                    }`}
                    type={headerTextType}
                  >
                    {leftHeader}
                  </BodyText>

                  <BodyText className="bodytext-local" type={bodyTextType}>
                    {leftBodytext}
                  </BodyText>
                </div>
              ),
              () => (
                <div className={`middle${headerText ? '-1' : '-2'}`}>
                  <BodyText
                    className={`text-header${
                      moleculeType === List3Groups.types.LINK ? ' hiding' : ''
                    }`}
                    type={headerTextType}
                  >
                    {middleHeader}
                  </BodyText>

                  <BodyText className="bodytext-local" type={bodyTextType}>
                    {middleBodytext}
                  </BodyText>
                </div>
              ),
              () => (
                <div className={`right${headerText ? '-1' : '-2'}`}>
                  <BodyText
                    className={`text-header${
                      moleculeType === List3Groups.types.LINK ? ' hiding' : ''
                    }`}
                    type={headerTextType}
                  >
                    {rightHeader}
                  </BodyText>

                  <BodyText className="bodytext-local" type={bodyTextType}>
                    {rightBodyText}
                  </BodyText>

                  {link && <CallToAction link={link} />}
                </div>
              ),
            ]}
          />
        </div>
      </div>
    );
  }
}

export default List3Groups;
