export const colorEnum = {
  black: '#141C21',
  white: '#FFFFFF',
  blue: '#3d8af9',
  gray: '#bebebe',
  lightGray: '#FAF9F8',
  brightGray: '#eaeaea',
  lightBlue: '#71abff',
  novatarisBlue: '#3D8AF9',
  darkGrey: '#626567',
  green: '#32a237',
  paleGreen: '#EAFFBB',
} as const;

export const defaultColors = {
  header: colorEnum.black,
  lightHeader: '#b3b6b7',
  bodyText: colorEnum.black,
  linkHover: '#EAFFBB',
  lineBreak: '#e8ebed',
} as const;
