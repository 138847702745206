import { Component } from 'react';

import BuzzListMolecule from './common/molecules/BuzzList';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';

const styles = {
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '84px',
    paddingBottom: '84px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '70px',
    paddingBottom: '70px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '62px',
    paddingBottom: '62px',
  },
};

type BuzzListProps = {
  header?: InternalPropTypes.RichText;
  buzzWords?: InternalPropTypes.RichText[];
  text?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
  backgroundColor?: string;
};

class BuzzList extends Component<BuzzListProps> {
  static defaultProps = {
    header: null,
    buzzWords: [],
    text: null,
    link: null,
    backgroundColor: null,
  };

  render() {
    const { buzzWords, header, text, link, backgroundColor } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="buzz-list-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <BuzzListMolecule
              buzzWords={buzzWords}
              header={header}
              text={text}
              link={link}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default BuzzList;
